
.icon-emote {
	background-image: url(./emote.png);
	width: 40px;
	height: 40px;
}
.icon-emote-101 { background-position: 0px 0px }
.icon-emote-102 { background-position: -40px 0px }
.icon-emote-103 { background-position: 0px -40px }
.icon-emote-104 { background-position: -40px -40px }
.icon-emote-109 { background-position: -80px 0px }
.icon-emote-110 { background-position: -80px -40px }
.icon-emote-113 { background-position: 0px -80px }
.icon-emote-114 { background-position: -40px -80px }
.icon-emote-115 { background-position: -80px -80px }
.icon-emote-118 { background-position: -120px 0px }
.icon-emote-119 { background-position: -120px -40px }
.icon-emote-120 { background-position: -120px -80px }
.icon-emote-121 { background-position: 0px -120px }
.icon-emote-122 { background-position: -40px -120px }
.icon-emote-123 { background-position: -80px -120px }
.icon-emote-124 { background-position: -120px -120px }
.icon-emote-125 { background-position: -160px 0px }
.icon-emote-126 { background-position: -160px -40px }
.icon-emote-127 { background-position: -160px -80px }
.icon-emote-128 { background-position: -160px -120px }
.icon-emote-129 { background-position: 0px -160px }
.icon-emote-130 { background-position: -40px -160px }
.icon-emote-131 { background-position: -80px -160px }
.icon-emote-132 { background-position: -120px -160px }
.icon-emote-134 { background-position: -160px -160px }
.icon-emote-135 { background-position: -200px 0px }
.icon-emote-136 { background-position: -200px -40px }
.icon-emote-138 { background-position: -200px -80px }
.icon-emote-142 { background-position: -200px -120px }
.icon-emote-143 { background-position: -200px -160px }
.icon-emote-144 { background-position: 0px -200px }
.icon-emote-145 { background-position: -40px -200px }
.icon-emote-146 { background-position: -80px -200px }
.icon-emote-148 { background-position: -120px -200px }
.icon-emote-149 { background-position: -160px -200px }
.icon-emote-151 { background-position: -200px -200px }
.icon-emote-153 { background-position: -240px 0px }
.icon-emote-154 { background-position: -240px -40px }
.icon-emote-155 { background-position: -240px -80px }
.icon-emote-156 { background-position: -240px -120px }
.icon-emote-157 { background-position: -240px -160px }
.icon-emote-158 { background-position: -240px -200px }
.icon-emote-164 { background-position: 0px -240px }
.icon-emote-165 { background-position: -40px -240px }
.icon-emote-166 { background-position: -80px -240px }
.icon-emote-167 { background-position: -120px -240px }
.icon-emote-169 { background-position: -160px -240px }
.icon-emote-170 { background-position: -200px -240px }
.icon-emote-171 { background-position: -240px -240px }
.icon-emote-172 { background-position: -280px 0px }
.icon-emote-173 { background-position: -280px -40px }
.icon-emote-174 { background-position: -280px -80px }
.icon-emote-175 { background-position: -280px -120px }
.icon-emote-176 { background-position: -280px -160px }
.icon-emote-178 { background-position: -280px -200px }
.icon-emote-180 { background-position: -280px -240px }
.icon-emote-181 { background-position: 0px -280px }
.icon-emote-182 { background-position: -40px -280px }
.icon-emote-183 { background-position: -80px -280px }
.icon-emote-185 { background-position: -120px -280px }
.icon-emote-186 { background-position: -160px -280px }
.icon-emote-187 { background-position: -200px -280px }
.icon-emote-188 { background-position: -240px -280px }
.icon-emote-189 { background-position: -280px -280px }
.icon-emote-190 { background-position: -320px 0px }
.icon-emote-191 { background-position: -320px -40px }
.icon-emote-192 { background-position: -320px -80px }
.icon-emote-193 { background-position: -320px -120px }
.icon-emote-194 { background-position: -320px -160px }
.icon-emote-195 { background-position: -320px -200px }
.icon-emote-196 { background-position: -320px -240px }
.icon-emote-197 { background-position: -320px -280px }
.icon-emote-198 { background-position: 0px -320px }
.icon-emote-199 { background-position: -40px -320px }
.icon-emote-200 { background-position: -80px -320px }
.icon-emote-201 { background-position: -120px -320px }
.icon-emote-202 { background-position: -160px -320px }
.icon-emote-203 { background-position: -200px -320px }
.icon-emote-204 { background-position: -240px -320px }
.icon-emote-205 { background-position: -280px -320px }
.icon-emote-206 { background-position: -320px -320px }
.icon-emote-207 { background-position: -360px 0px }
.icon-emote-208 { background-position: -360px -40px }
.icon-emote-209 { background-position: -360px -80px }
.icon-emote-210 { background-position: -360px -120px }
.icon-emote-211 { background-position: -360px -160px }
.icon-emote-212 { background-position: -360px -200px }
.icon-emote-213 { background-position: -360px -240px }
.icon-emote-214 { background-position: -360px -280px }
.icon-emote-215 { background-position: -360px -320px }
.icon-emote-216 { background-position: 0px -360px }
.icon-emote-217 { background-position: -40px -360px }
.icon-emote-220 { background-position: -80px -360px }
.icon-emote-221 { background-position: -120px -360px }
.icon-emote-222 { background-position: -160px -360px }
.icon-emote-223 { background-position: -200px -360px }
.icon-emote-224 { background-position: -240px -360px }
.icon-emote-225 { background-position: -280px -360px }
.icon-emote-226 { background-position: -320px -360px }
.icon-emote-227 { background-position: -360px -360px }
.icon-emote-229 { background-position: -400px 0px }
.icon-emote-230 { background-position: -400px -40px }
.icon-emote-231 { background-position: -400px -80px }
.icon-emote-232 { background-position: -400px -120px }
.icon-emote-234 { background-position: -400px -160px }
.icon-emote-235 { background-position: -400px -200px }
.icon-emote-237 { background-position: -400px -240px }
.icon-emote-238 { background-position: -400px -280px }
.icon-emote-239 { background-position: -400px -320px }
.icon-emote-240 { background-position: -400px -360px }
.icon-emote-241 { background-position: 0px -400px }
.icon-emote-242 { background-position: -40px -400px }
.icon-emote-245 { background-position: -80px -400px }
.icon-emote-246 { background-position: -120px -400px }
.icon-emote-247 { background-position: -160px -400px }
.icon-emote-248 { background-position: -200px -400px }
.icon-emote-249 { background-position: -240px -400px }
.icon-emote-250 { background-position: -280px -400px }
.icon-emote-251 { background-position: -320px -400px }
.icon-emote-252 { background-position: -360px -400px }
.icon-emote-256 { background-position: -400px -400px }
.icon-emote-257 { background-position: -440px 0px }
.icon-emote-258 { background-position: -440px -40px }
.icon-emote-259 { background-position: -440px -80px }
.icon-emote-260 { background-position: -440px -120px }
.icon-emote-261 { background-position: -440px -160px }
.icon-emote-262 { background-position: -440px -200px }
.icon-emote-263 { background-position: -440px -240px }
.icon-emote-264 { background-position: -440px -280px }
.icon-emote-265 { background-position: -440px -320px }
.icon-emote-266 { background-position: -440px -360px }
.icon-emote-267 { background-position: -440px -400px }
.icon-emote-269 { background-position: 0px -440px }
.icon-emote-270 { background-position: -40px -440px }
.icon-emote-271 { background-position: -80px -440px }
.icon-emote-272 { background-position: -120px -440px }
.icon-emote-273 { background-position: -160px -440px }
.icon-emote-274 { background-position: -200px -440px }
.icon-emote-275 { background-position: -240px -440px }
.icon-emote-276 { background-position: -280px -440px }
.icon-emote-277 { background-position: -320px -440px }
.icon-emote-278 { background-position: -360px -440px }
.icon-emote-279 { background-position: -400px -440px }
.icon-emote-280 { background-position: -440px -440px }
.icon-emote-281 { background-position: -480px 0px }
.icon-emote-282 { background-position: -480px -40px }
.icon-emote-283 { background-position: -480px -80px }
.icon-emote-284 { background-position: -480px -120px }
.icon-emote-285 { background-position: -480px -160px }
.icon-emote-291 { background-position: -480px -200px }
.icon-emote-293 { background-position: -480px -240px }
.icon-emote-295 { background-position: -480px -280px }
.icon-emote-296 { background-position: -480px -320px }
.icon-emote-59 { background-position: -480px -360px }
.icon-emote-62 { background-position: -480px -400px }
.icon-emote-63 { background-position: -480px -440px }
.icon-emote-64 { background-position: 0px -480px }
.icon-emote-65 { background-position: -40px -480px }
.icon-emote-66 { background-position: -80px -480px }
.icon-emote-67 { background-position: -120px -480px }
.icon-emote-81 { background-position: -160px -480px }
.icon-emote-82 { background-position: -200px -480px }
.icon-emote-85 { background-position: -240px -480px }