
.icon-fish {
	background-image: url(./fish.png);
	width: 40px;
	height: 40px;
}
.icon-fish-1 { background-position: 0px 0px }
.icon-fish-10 { background-position: -40px 0px }
.icon-fish-100 { background-position: 0px -40px }
.icon-fish-1000 { background-position: -40px -40px }
.icon-fish-1001 { background-position: -80px 0px }
.icon-fish-1002 { background-position: -80px -40px }
.icon-fish-1003 { background-position: 0px -80px }
.icon-fish-1004 { background-position: -40px -80px }
.icon-fish-1005 { background-position: -80px -80px }
.icon-fish-1006 { background-position: -120px 0px }
.icon-fish-1007 { background-position: -120px -40px }
.icon-fish-1008 { background-position: -120px -80px }
.icon-fish-1009 { background-position: 0px -120px }
.icon-fish-101 { background-position: -40px -120px }
.icon-fish-1010 { background-position: -80px -120px }
.icon-fish-1011 { background-position: -120px -120px }
.icon-fish-1012 { background-position: -160px 0px }
.icon-fish-1013 { background-position: -160px -40px }
.icon-fish-1014 { background-position: -160px -80px }
.icon-fish-1015 { background-position: -160px -120px }
.icon-fish-1016 { background-position: 0px -160px }
.icon-fish-1017 { background-position: -40px -160px }
.icon-fish-1018 { background-position: -80px -160px }
.icon-fish-1019 { background-position: -120px -160px }
.icon-fish-102 { background-position: -160px -160px }
.icon-fish-1020 { background-position: -200px 0px }
.icon-fish-1021 { background-position: -200px -40px }
.icon-fish-1022 { background-position: -200px -80px }
.icon-fish-1023 { background-position: -200px -120px }
.icon-fish-1024 { background-position: -200px -160px }
.icon-fish-1025 { background-position: 0px -200px }
.icon-fish-1026 { background-position: -40px -200px }
.icon-fish-1027 { background-position: -80px -200px }
.icon-fish-1028 { background-position: -120px -200px }
.icon-fish-1029 { background-position: -160px -200px }
.icon-fish-103 { background-position: -200px -200px }
.icon-fish-1030 { background-position: -240px 0px }
.icon-fish-1031 { background-position: -240px -40px }
.icon-fish-1032 { background-position: -240px -80px }
.icon-fish-1033 { background-position: -240px -120px }
.icon-fish-1034 { background-position: -240px -160px }
.icon-fish-1035 { background-position: -240px -200px }
.icon-fish-1036 { background-position: 0px -240px }
.icon-fish-1037 { background-position: -40px -240px }
.icon-fish-1038 { background-position: -80px -240px }
.icon-fish-1039 { background-position: -120px -240px }
.icon-fish-104 { background-position: -160px -240px }
.icon-fish-1040 { background-position: -200px -240px }
.icon-fish-1041 { background-position: -240px -240px }
.icon-fish-1042 { background-position: -280px 0px }
.icon-fish-1043 { background-position: -280px -40px }
.icon-fish-1044 { background-position: -280px -80px }
.icon-fish-1045 { background-position: -280px -120px }
.icon-fish-1046 { background-position: -280px -160px }
.icon-fish-1047 { background-position: -280px -200px }
.icon-fish-1048 { background-position: -280px -240px }
.icon-fish-1049 { background-position: 0px -280px }
.icon-fish-105 { background-position: -40px -280px }
.icon-fish-1050 { background-position: -80px -280px }
.icon-fish-1051 { background-position: -120px -280px }
.icon-fish-1052 { background-position: -160px -280px }
.icon-fish-1053 { background-position: -200px -280px }
.icon-fish-1054 { background-position: -240px -280px }
.icon-fish-1055 { background-position: -280px -280px }
.icon-fish-1056 { background-position: -320px 0px }
.icon-fish-1057 { background-position: -320px -40px }
.icon-fish-1058 { background-position: -320px -80px }
.icon-fish-1059 { background-position: -320px -120px }
.icon-fish-106 { background-position: -320px -160px }
.icon-fish-1060 { background-position: -320px -200px }
.icon-fish-1061 { background-position: -320px -240px }
.icon-fish-1062 { background-position: -320px -280px }
.icon-fish-1063 { background-position: 0px -320px }
.icon-fish-1064 { background-position: -40px -320px }
.icon-fish-1065 { background-position: -80px -320px }
.icon-fish-1066 { background-position: -120px -320px }
.icon-fish-1067 { background-position: -160px -320px }
.icon-fish-1068 { background-position: -200px -320px }
.icon-fish-1069 { background-position: -240px -320px }
.icon-fish-107 { background-position: -280px -320px }
.icon-fish-1070 { background-position: -320px -320px }
.icon-fish-1071 { background-position: -360px 0px }
.icon-fish-1072 { background-position: -360px -40px }
.icon-fish-1073 { background-position: -360px -80px }
.icon-fish-1074 { background-position: -360px -120px }
.icon-fish-1075 { background-position: -360px -160px }
.icon-fish-1076 { background-position: -360px -200px }
.icon-fish-1077 { background-position: -360px -240px }
.icon-fish-1078 { background-position: -360px -280px }
.icon-fish-1079 { background-position: -360px -320px }
.icon-fish-108 { background-position: 0px -360px }
.icon-fish-1080 { background-position: -40px -360px }
.icon-fish-1081 { background-position: -80px -360px }
.icon-fish-1082 { background-position: -120px -360px }
.icon-fish-1083 { background-position: -160px -360px }
.icon-fish-1084 { background-position: -200px -360px }
.icon-fish-1085 { background-position: -240px -360px }
.icon-fish-1086 { background-position: -280px -360px }
.icon-fish-1087 { background-position: -320px -360px }
.icon-fish-1088 { background-position: -360px -360px }
.icon-fish-1089 { background-position: -400px 0px }
.icon-fish-109 { background-position: -400px -40px }
.icon-fish-1090 { background-position: -400px -80px }
.icon-fish-1091 { background-position: -400px -120px }
.icon-fish-1092 { background-position: -400px -160px }
.icon-fish-1093 { background-position: -400px -200px }
.icon-fish-1094 { background-position: -400px -240px }
.icon-fish-1095 { background-position: -400px -280px }
.icon-fish-1096 { background-position: -400px -320px }
.icon-fish-1097 { background-position: -400px -360px }
.icon-fish-1098 { background-position: 0px -400px }
.icon-fish-1099 { background-position: -40px -400px }
.icon-fish-11 { background-position: -80px -400px }
.icon-fish-110 { background-position: -120px -400px }
.icon-fish-1100 { background-position: -160px -400px }
.icon-fish-1101 { background-position: -200px -400px }
.icon-fish-1102 { background-position: -240px -400px }
.icon-fish-1103 { background-position: -280px -400px }
.icon-fish-1104 { background-position: -320px -400px }
.icon-fish-1105 { background-position: -360px -400px }
.icon-fish-1106 { background-position: -400px -400px }
.icon-fish-1107 { background-position: -440px 0px }
.icon-fish-1108 { background-position: -440px -40px }
.icon-fish-1109 { background-position: -440px -80px }
.icon-fish-111 { background-position: -440px -120px }
.icon-fish-1110 { background-position: -440px -160px }
.icon-fish-1111 { background-position: -440px -200px }
.icon-fish-1112 { background-position: -440px -240px }
.icon-fish-1113 { background-position: -440px -280px }
.icon-fish-1114 { background-position: -440px -320px }
.icon-fish-1115 { background-position: -440px -360px }
.icon-fish-1116 { background-position: -440px -400px }
.icon-fish-1117 { background-position: 0px -440px }
.icon-fish-1118 { background-position: -40px -440px }
.icon-fish-1119 { background-position: -80px -440px }
.icon-fish-112 { background-position: -120px -440px }
.icon-fish-1120 { background-position: -160px -440px }
.icon-fish-1121 { background-position: -200px -440px }
.icon-fish-1122 { background-position: -240px -440px }
.icon-fish-1123 { background-position: -280px -440px }
.icon-fish-1124 { background-position: -320px -440px }
.icon-fish-1125 { background-position: -360px -440px }
.icon-fish-1126 { background-position: -400px -440px }
.icon-fish-1127 { background-position: -440px -440px }
.icon-fish-1128 { background-position: -480px 0px }
.icon-fish-1129 { background-position: -480px -40px }
.icon-fish-113 { background-position: -480px -80px }
.icon-fish-1130 { background-position: -480px -120px }
.icon-fish-1131 { background-position: -480px -160px }
.icon-fish-1132 { background-position: -480px -200px }
.icon-fish-1133 { background-position: -480px -240px }
.icon-fish-1134 { background-position: -480px -280px }
.icon-fish-1135 { background-position: -480px -320px }
.icon-fish-1136 { background-position: -480px -360px }
.icon-fish-1137 { background-position: -480px -400px }
.icon-fish-1138 { background-position: -480px -440px }
.icon-fish-1139 { background-position: 0px -480px }
.icon-fish-114 { background-position: -40px -480px }
.icon-fish-1140 { background-position: -80px -480px }
.icon-fish-1141 { background-position: -120px -480px }
.icon-fish-1142 { background-position: -160px -480px }
.icon-fish-1143 { background-position: -200px -480px }
.icon-fish-1144 { background-position: -240px -480px }
.icon-fish-1145 { background-position: -280px -480px }
.icon-fish-1146 { background-position: -320px -480px }
.icon-fish-1147 { background-position: -360px -480px }
.icon-fish-1148 { background-position: -400px -480px }
.icon-fish-1149 { background-position: -440px -480px }
.icon-fish-115 { background-position: -480px -480px }
.icon-fish-1150 { background-position: -520px 0px }
.icon-fish-1151 { background-position: -520px -40px }
.icon-fish-1152 { background-position: -520px -80px }
.icon-fish-1153 { background-position: -520px -120px }
.icon-fish-1154 { background-position: -520px -160px }
.icon-fish-1155 { background-position: -520px -200px }
.icon-fish-1156 { background-position: -520px -240px }
.icon-fish-1157 { background-position: -520px -280px }
.icon-fish-1158 { background-position: -520px -320px }
.icon-fish-1159 { background-position: -520px -360px }
.icon-fish-116 { background-position: -520px -400px }
.icon-fish-1160 { background-position: -520px -440px }
.icon-fish-1161 { background-position: -520px -480px }
.icon-fish-1162 { background-position: 0px -520px }
.icon-fish-1163 { background-position: -40px -520px }
.icon-fish-1164 { background-position: -80px -520px }
.icon-fish-1165 { background-position: -120px -520px }
.icon-fish-1166 { background-position: -160px -520px }
.icon-fish-1167 { background-position: -200px -520px }
.icon-fish-1168 { background-position: -240px -520px }
.icon-fish-1169 { background-position: -280px -520px }
.icon-fish-117 { background-position: -320px -520px }
.icon-fish-1170 { background-position: -360px -520px }
.icon-fish-1171 { background-position: -400px -520px }
.icon-fish-1172 { background-position: -440px -520px }
.icon-fish-1173 { background-position: -480px -520px }
.icon-fish-1174 { background-position: -520px -520px }
.icon-fish-1175 { background-position: -560px 0px }
.icon-fish-1176 { background-position: -560px -40px }
.icon-fish-1177 { background-position: -560px -80px }
.icon-fish-1178 { background-position: -560px -120px }
.icon-fish-1179 { background-position: -560px -160px }
.icon-fish-118 { background-position: -560px -200px }
.icon-fish-1180 { background-position: -560px -240px }
.icon-fish-1181 { background-position: -560px -280px }
.icon-fish-1182 { background-position: -560px -320px }
.icon-fish-1183 { background-position: -560px -360px }
.icon-fish-1184 { background-position: -560px -400px }
.icon-fish-1185 { background-position: -560px -440px }
.icon-fish-1186 { background-position: -560px -480px }
.icon-fish-1187 { background-position: -560px -520px }
.icon-fish-1188 { background-position: 0px -560px }
.icon-fish-1189 { background-position: -40px -560px }
.icon-fish-119 { background-position: -80px -560px }
.icon-fish-1190 { background-position: -120px -560px }
.icon-fish-1191 { background-position: -160px -560px }
.icon-fish-1192 { background-position: -200px -560px }
.icon-fish-1193 { background-position: -240px -560px }
.icon-fish-1194 { background-position: -280px -560px }
.icon-fish-1195 { background-position: -320px -560px }
.icon-fish-1196 { background-position: -360px -560px }
.icon-fish-1197 { background-position: -400px -560px }
.icon-fish-1198 { background-position: -440px -560px }
.icon-fish-1199 { background-position: -480px -560px }
.icon-fish-12 { background-position: -520px -560px }
.icon-fish-120 { background-position: -560px -560px }
.icon-fish-1200 { background-position: -600px 0px }
.icon-fish-1201 { background-position: -600px -40px }
.icon-fish-1202 { background-position: -600px -80px }
.icon-fish-1203 { background-position: -600px -120px }
.icon-fish-1204 { background-position: -600px -160px }
.icon-fish-1205 { background-position: -600px -200px }
.icon-fish-1206 { background-position: -600px -240px }
.icon-fish-1207 { background-position: -600px -280px }
.icon-fish-1208 { background-position: -600px -320px }
.icon-fish-1209 { background-position: -600px -360px }
.icon-fish-121 { background-position: -600px -400px }
.icon-fish-1210 { background-position: -600px -440px }
.icon-fish-1211 { background-position: -600px -480px }
.icon-fish-1212 { background-position: -600px -520px }
.icon-fish-1213 { background-position: -600px -560px }
.icon-fish-1214 { background-position: 0px -600px }
.icon-fish-1215 { background-position: -40px -600px }
.icon-fish-1216 { background-position: -80px -600px }
.icon-fish-1217 { background-position: -120px -600px }
.icon-fish-1218 { background-position: -160px -600px }
.icon-fish-1219 { background-position: -200px -600px }
.icon-fish-122 { background-position: -240px -600px }
.icon-fish-1220 { background-position: -280px -600px }
.icon-fish-1221 { background-position: -320px -600px }
.icon-fish-1222 { background-position: -360px -600px }
.icon-fish-1223 { background-position: -400px -600px }
.icon-fish-1224 { background-position: -440px -600px }
.icon-fish-1225 { background-position: -480px -600px }
.icon-fish-1226 { background-position: -520px -600px }
.icon-fish-1227 { background-position: -560px -600px }
.icon-fish-1228 { background-position: -600px -600px }
.icon-fish-1229 { background-position: -640px 0px }
.icon-fish-123 { background-position: -640px -40px }
.icon-fish-1230 { background-position: -640px -80px }
.icon-fish-1231 { background-position: -640px -120px }
.icon-fish-1232 { background-position: -640px -160px }
.icon-fish-1233 { background-position: -640px -200px }
.icon-fish-1234 { background-position: -640px -240px }
.icon-fish-1235 { background-position: -640px -280px }
.icon-fish-1236 { background-position: -640px -320px }
.icon-fish-1237 { background-position: -640px -360px }
.icon-fish-1238 { background-position: -640px -400px }
.icon-fish-1239 { background-position: -640px -440px }
.icon-fish-124 { background-position: -640px -480px }
.icon-fish-1240 { background-position: -640px -520px }
.icon-fish-1241 { background-position: -640px -560px }
.icon-fish-1242 { background-position: -640px -600px }
.icon-fish-1243 { background-position: 0px -640px }
.icon-fish-1244 { background-position: -40px -640px }
.icon-fish-1245 { background-position: -80px -640px }
.icon-fish-1246 { background-position: -120px -640px }
.icon-fish-1247 { background-position: -160px -640px }
.icon-fish-1248 { background-position: -200px -640px }
.icon-fish-1249 { background-position: -240px -640px }
.icon-fish-125 { background-position: -280px -640px }
.icon-fish-1250 { background-position: -320px -640px }
.icon-fish-1251 { background-position: -360px -640px }
.icon-fish-1252 { background-position: -400px -640px }
.icon-fish-1253 { background-position: -440px -640px }
.icon-fish-1254 { background-position: -480px -640px }
.icon-fish-1255 { background-position: -520px -640px }
.icon-fish-1256 { background-position: -560px -640px }
.icon-fish-1257 { background-position: -600px -640px }
.icon-fish-1258 { background-position: -640px -640px }
.icon-fish-1259 { background-position: -680px 0px }
.icon-fish-126 { background-position: -680px -40px }
.icon-fish-1260 { background-position: -680px -80px }
.icon-fish-1261 { background-position: -680px -120px }
.icon-fish-1262 { background-position: -680px -160px }
.icon-fish-1263 { background-position: -680px -200px }
.icon-fish-1264 { background-position: -680px -240px }
.icon-fish-1265 { background-position: -680px -280px }
.icon-fish-1266 { background-position: -680px -320px }
.icon-fish-1267 { background-position: -680px -360px }
.icon-fish-1268 { background-position: -680px -400px }
.icon-fish-1269 { background-position: -680px -440px }
.icon-fish-127 { background-position: -680px -480px }
.icon-fish-1270 { background-position: -680px -520px }
.icon-fish-1271 { background-position: -680px -560px }
.icon-fish-1272 { background-position: -680px -600px }
.icon-fish-1273 { background-position: -680px -640px }
.icon-fish-1274 { background-position: 0px -680px }
.icon-fish-1275 { background-position: -40px -680px }
.icon-fish-1276 { background-position: -80px -680px }
.icon-fish-1277 { background-position: -120px -680px }
.icon-fish-1278 { background-position: -160px -680px }
.icon-fish-1279 { background-position: -200px -680px }
.icon-fish-128 { background-position: -240px -680px }
.icon-fish-1280 { background-position: -280px -680px }
.icon-fish-1281 { background-position: -320px -680px }
.icon-fish-1282 { background-position: -360px -680px }
.icon-fish-1283 { background-position: -400px -680px }
.icon-fish-1284 { background-position: -440px -680px }
.icon-fish-1285 { background-position: -480px -680px }
.icon-fish-1286 { background-position: -520px -680px }
.icon-fish-1287 { background-position: -560px -680px }
.icon-fish-1288 { background-position: -600px -680px }
.icon-fish-1289 { background-position: -640px -680px }
.icon-fish-129 { background-position: -680px -680px }
.icon-fish-1290 { background-position: -720px 0px }
.icon-fish-1291 { background-position: -720px -40px }
.icon-fish-1292 { background-position: -720px -80px }
.icon-fish-1293 { background-position: -720px -120px }
.icon-fish-1295 { background-position: -720px -160px }
.icon-fish-1296 { background-position: -720px -200px }
.icon-fish-1297 { background-position: -720px -240px }
.icon-fish-1298 { background-position: -720px -280px }
.icon-fish-1299 { background-position: -720px -320px }
.icon-fish-13 { background-position: -720px -360px }
.icon-fish-130 { background-position: -720px -400px }
.icon-fish-1300 { background-position: -720px -440px }
.icon-fish-1301 { background-position: -720px -480px }
.icon-fish-1302 { background-position: -720px -520px }
.icon-fish-1303 { background-position: -720px -560px }
.icon-fish-1304 { background-position: -720px -600px }
.icon-fish-1305 { background-position: -720px -640px }
.icon-fish-1306 { background-position: -720px -680px }
.icon-fish-1307 { background-position: 0px -720px }
.icon-fish-1308 { background-position: -40px -720px }
.icon-fish-131 { background-position: -80px -720px }
.icon-fish-1310 { background-position: -120px -720px }
.icon-fish-1311 { background-position: -160px -720px }
.icon-fish-1312 { background-position: -200px -720px }
.icon-fish-1313 { background-position: -240px -720px }
.icon-fish-1314 { background-position: -280px -720px }
.icon-fish-1315 { background-position: -320px -720px }
.icon-fish-1317 { background-position: -360px -720px }
.icon-fish-1318 { background-position: -400px -720px }
.icon-fish-1319 { background-position: -440px -720px }
.icon-fish-132 { background-position: -480px -720px }
.icon-fish-1320 { background-position: -520px -720px }
.icon-fish-1321 { background-position: -560px -720px }
.icon-fish-1322 { background-position: -600px -720px }
.icon-fish-1323 { background-position: -640px -720px }
.icon-fish-1324 { background-position: -680px -720px }
.icon-fish-1325 { background-position: -720px -720px }
.icon-fish-1326 { background-position: -760px 0px }
.icon-fish-1327 { background-position: -760px -40px }
.icon-fish-1328 { background-position: -760px -80px }
.icon-fish-1329 { background-position: -760px -120px }
.icon-fish-133 { background-position: -760px -160px }
.icon-fish-1330 { background-position: -760px -200px }
.icon-fish-1331 { background-position: -760px -240px }
.icon-fish-1332 { background-position: -760px -280px }
.icon-fish-1333 { background-position: -760px -320px }
.icon-fish-1334 { background-position: -760px -360px }
.icon-fish-1335 { background-position: -760px -400px }
.icon-fish-1336 { background-position: -760px -440px }
.icon-fish-1337 { background-position: -760px -480px }
.icon-fish-1338 { background-position: -760px -520px }
.icon-fish-1339 { background-position: -760px -560px }
.icon-fish-134 { background-position: -760px -600px }
.icon-fish-1340 { background-position: -760px -640px }
.icon-fish-1341 { background-position: -760px -680px }
.icon-fish-1342 { background-position: -760px -720px }
.icon-fish-1343 { background-position: 0px -760px }
.icon-fish-1344 { background-position: -40px -760px }
.icon-fish-1345 { background-position: -80px -760px }
.icon-fish-1346 { background-position: -120px -760px }
.icon-fish-1347 { background-position: -160px -760px }
.icon-fish-1348 { background-position: -200px -760px }
.icon-fish-1349 { background-position: -240px -760px }
.icon-fish-135 { background-position: -280px -760px }
.icon-fish-1350 { background-position: -320px -760px }
.icon-fish-1351 { background-position: -360px -760px }
.icon-fish-1352 { background-position: -400px -760px }
.icon-fish-1353 { background-position: -440px -760px }
.icon-fish-1354 { background-position: -480px -760px }
.icon-fish-1355 { background-position: -520px -760px }
.icon-fish-1356 { background-position: -560px -760px }
.icon-fish-1357 { background-position: -600px -760px }
.icon-fish-1358 { background-position: -640px -760px }
.icon-fish-1359 { background-position: -680px -760px }
.icon-fish-136 { background-position: -720px -760px }
.icon-fish-1360 { background-position: -760px -760px }
.icon-fish-1361 { background-position: -800px 0px }
.icon-fish-1362 { background-position: -800px -40px }
.icon-fish-1363 { background-position: -800px -80px }
.icon-fish-1364 { background-position: -800px -120px }
.icon-fish-1365 { background-position: -800px -160px }
.icon-fish-1366 { background-position: -800px -200px }
.icon-fish-1367 { background-position: -800px -240px }
.icon-fish-1368 { background-position: -800px -280px }
.icon-fish-1369 { background-position: -800px -320px }
.icon-fish-137 { background-position: -800px -360px }
.icon-fish-1370 { background-position: -800px -400px }
.icon-fish-1371 { background-position: -800px -440px }
.icon-fish-1372 { background-position: -800px -480px }
.icon-fish-1373 { background-position: -800px -520px }
.icon-fish-1374 { background-position: -800px -560px }
.icon-fish-1375 { background-position: -800px -600px }
.icon-fish-1376 { background-position: -800px -640px }
.icon-fish-1377 { background-position: -800px -680px }
.icon-fish-1378 { background-position: -800px -720px }
.icon-fish-1379 { background-position: -800px -760px }
.icon-fish-138 { background-position: 0px -800px }
.icon-fish-1380 { background-position: -40px -800px }
.icon-fish-1381 { background-position: -80px -800px }
.icon-fish-1382 { background-position: -120px -800px }
.icon-fish-1383 { background-position: -160px -800px }
.icon-fish-1384 { background-position: -200px -800px }
.icon-fish-1385 { background-position: -240px -800px }
.icon-fish-1386 { background-position: -280px -800px }
.icon-fish-1388 { background-position: -320px -800px }
.icon-fish-1389 { background-position: -360px -800px }
.icon-fish-139 { background-position: -400px -800px }
.icon-fish-1390 { background-position: -440px -800px }
.icon-fish-1391 { background-position: -480px -800px }
.icon-fish-1392 { background-position: -520px -800px }
.icon-fish-1393 { background-position: -560px -800px }
.icon-fish-1394 { background-position: -600px -800px }
.icon-fish-1395 { background-position: -640px -800px }
.icon-fish-1396 { background-position: -680px -800px }
.icon-fish-1397 { background-position: -720px -800px }
.icon-fish-1398 { background-position: -760px -800px }
.icon-fish-1399 { background-position: -800px -800px }
.icon-fish-14 { background-position: -840px 0px }
.icon-fish-140 { background-position: -840px -40px }
.icon-fish-1400 { background-position: -840px -80px }
.icon-fish-1401 { background-position: -840px -120px }
.icon-fish-1402 { background-position: -840px -160px }
.icon-fish-1403 { background-position: -840px -200px }
.icon-fish-1404 { background-position: -840px -240px }
.icon-fish-1405 { background-position: -840px -280px }
.icon-fish-1406 { background-position: -840px -320px }
.icon-fish-1407 { background-position: -840px -360px }
.icon-fish-1408 { background-position: -840px -400px }
.icon-fish-1409 { background-position: -840px -440px }
.icon-fish-141 { background-position: -840px -480px }
.icon-fish-1410 { background-position: -840px -520px }
.icon-fish-1411 { background-position: -840px -560px }
.icon-fish-1412 { background-position: -840px -600px }
.icon-fish-1413 { background-position: -840px -640px }
.icon-fish-1414 { background-position: -840px -680px }
.icon-fish-1415 { background-position: -840px -720px }
.icon-fish-1416 { background-position: -840px -760px }
.icon-fish-1417 { background-position: -840px -800px }
.icon-fish-1418 { background-position: 0px -840px }
.icon-fish-1419 { background-position: -40px -840px }
.icon-fish-142 { background-position: -80px -840px }
.icon-fish-1420 { background-position: -120px -840px }
.icon-fish-1421 { background-position: -160px -840px }
.icon-fish-1422 { background-position: -200px -840px }
.icon-fish-1423 { background-position: -240px -840px }
.icon-fish-1424 { background-position: -280px -840px }
.icon-fish-1425 { background-position: -320px -840px }
.icon-fish-1426 { background-position: -360px -840px }
.icon-fish-1427 { background-position: -400px -840px }
.icon-fish-1428 { background-position: -440px -840px }
.icon-fish-1429 { background-position: -480px -840px }
.icon-fish-143 { background-position: -520px -840px }
.icon-fish-1430 { background-position: -560px -840px }
.icon-fish-1431 { background-position: -600px -840px }
.icon-fish-1432 { background-position: -640px -840px }
.icon-fish-1433 { background-position: -680px -840px }
.icon-fish-1434 { background-position: -720px -840px }
.icon-fish-1435 { background-position: -760px -840px }
.icon-fish-1436 { background-position: -800px -840px }
.icon-fish-1437 { background-position: -840px -840px }
.icon-fish-1438 { background-position: -880px 0px }
.icon-fish-1439 { background-position: -880px -40px }
.icon-fish-144 { background-position: -880px -80px }
.icon-fish-1440 { background-position: -880px -120px }
.icon-fish-1441 { background-position: -880px -160px }
.icon-fish-1442 { background-position: -880px -200px }
.icon-fish-1443 { background-position: -880px -240px }
.icon-fish-1444 { background-position: -880px -280px }
.icon-fish-145 { background-position: -880px -320px }
.icon-fish-146 { background-position: -880px -360px }
.icon-fish-147 { background-position: -880px -400px }
.icon-fish-148 { background-position: -880px -440px }
.icon-fish-149 { background-position: -880px -480px }
.icon-fish-15 { background-position: -880px -520px }
.icon-fish-150 { background-position: -880px -560px }
.icon-fish-15000 { background-position: -880px -600px }
.icon-fish-15001 { background-position: -880px -640px }
.icon-fish-15002 { background-position: -880px -680px }
.icon-fish-15003 { background-position: -880px -720px }
.icon-fish-15004 { background-position: -880px -760px }
.icon-fish-15008 { background-position: -880px -800px }
.icon-fish-15009 { background-position: -880px -840px }
.icon-fish-15010 { background-position: 0px -880px }
.icon-fish-15012 { background-position: -40px -880px }
.icon-fish-15013 { background-position: -80px -880px }
.icon-fish-15014 { background-position: -120px -880px }
.icon-fish-15015 { background-position: -160px -880px }
.icon-fish-15016 { background-position: -200px -880px }
.icon-fish-15017 { background-position: -240px -880px }
.icon-fish-15018 { background-position: -280px -880px }
.icon-fish-15019 { background-position: -320px -880px }
.icon-fish-15020 { background-position: -360px -880px }
.icon-fish-15021 { background-position: -400px -880px }
.icon-fish-15022 { background-position: -440px -880px }
.icon-fish-15023 { background-position: -480px -880px }
.icon-fish-15024 { background-position: -520px -880px }
.icon-fish-15025 { background-position: -560px -880px }
.icon-fish-15027 { background-position: -600px -880px }
.icon-fish-15028 { background-position: -640px -880px }
.icon-fish-15029 { background-position: -680px -880px }
.icon-fish-15030 { background-position: -720px -880px }
.icon-fish-15031 { background-position: -760px -880px }
.icon-fish-15032 { background-position: -800px -880px }
.icon-fish-15033 { background-position: -840px -880px }
.icon-fish-15034 { background-position: -880px -880px }
.icon-fish-15035 { background-position: -920px 0px }
.icon-fish-15036 { background-position: -920px -40px }
.icon-fish-15037 { background-position: -920px -80px }
.icon-fish-15038 { background-position: -920px -120px }
.icon-fish-15039 { background-position: -920px -160px }
.icon-fish-15040 { background-position: -920px -200px }
.icon-fish-15041 { background-position: -920px -240px }
.icon-fish-15042 { background-position: -920px -280px }
.icon-fish-15043 { background-position: -920px -320px }
.icon-fish-15044 { background-position: -920px -360px }
.icon-fish-15045 { background-position: -920px -400px }
.icon-fish-15046 { background-position: -920px -440px }
.icon-fish-15047 { background-position: -920px -480px }
.icon-fish-15048 { background-position: -920px -520px }
.icon-fish-15049 { background-position: -920px -560px }
.icon-fish-15050 { background-position: -920px -600px }
.icon-fish-15051 { background-position: -920px -640px }
.icon-fish-15052 { background-position: -920px -680px }
.icon-fish-15053 { background-position: -920px -720px }
.icon-fish-15054 { background-position: -920px -760px }
.icon-fish-15055 { background-position: -920px -800px }
.icon-fish-15056 { background-position: -920px -840px }
.icon-fish-15057 { background-position: -920px -880px }
.icon-fish-15059 { background-position: 0px -920px }
.icon-fish-15060 { background-position: -40px -920px }
.icon-fish-15061 { background-position: -80px -920px }
.icon-fish-15062 { background-position: -120px -920px }
.icon-fish-15063 { background-position: -160px -920px }
.icon-fish-15064 { background-position: -200px -920px }
.icon-fish-15065 { background-position: -240px -920px }
.icon-fish-15066 { background-position: -280px -920px }
.icon-fish-15067 { background-position: -320px -920px }
.icon-fish-15068 { background-position: -360px -920px }
.icon-fish-15069 { background-position: -400px -920px }
.icon-fish-15070 { background-position: -440px -920px }
.icon-fish-15071 { background-position: -480px -920px }
.icon-fish-15072 { background-position: -520px -920px }
.icon-fish-15073 { background-position: -560px -920px }
.icon-fish-15074 { background-position: -600px -920px }
.icon-fish-15075 { background-position: -640px -920px }
.icon-fish-15076 { background-position: -680px -920px }
.icon-fish-15077 { background-position: -720px -920px }
.icon-fish-15078 { background-position: -760px -920px }
.icon-fish-15081 { background-position: -800px -920px }
.icon-fish-15082 { background-position: -840px -920px }
.icon-fish-15083 { background-position: -880px -920px }
.icon-fish-15084 { background-position: -920px -920px }
.icon-fish-15085 { background-position: -960px 0px }
.icon-fish-15086 { background-position: -960px -40px }
.icon-fish-15087 { background-position: -960px -80px }
.icon-fish-15088 { background-position: -960px -120px }
.icon-fish-15089 { background-position: -960px -160px }
.icon-fish-15090 { background-position: -960px -200px }
.icon-fish-15091 { background-position: -960px -240px }
.icon-fish-15092 { background-position: -960px -280px }
.icon-fish-15093 { background-position: -960px -320px }
.icon-fish-15094 { background-position: -960px -360px }
.icon-fish-15095 { background-position: -960px -400px }
.icon-fish-15096 { background-position: -960px -440px }
.icon-fish-15097 { background-position: -960px -480px }
.icon-fish-15098 { background-position: -960px -520px }
.icon-fish-15099 { background-position: -960px -560px }
.icon-fish-151 { background-position: -960px -600px }
.icon-fish-15100 { background-position: -960px -640px }
.icon-fish-15101 { background-position: -960px -680px }
.icon-fish-15102 { background-position: -960px -720px }
.icon-fish-15103 { background-position: -960px -760px }
.icon-fish-15104 { background-position: -960px -800px }
.icon-fish-15105 { background-position: -960px -840px }
.icon-fish-15106 { background-position: -960px -880px }
.icon-fish-15107 { background-position: -960px -920px }
.icon-fish-15108 { background-position: 0px -960px }
.icon-fish-15109 { background-position: -40px -960px }
.icon-fish-15110 { background-position: -80px -960px }
.icon-fish-15111 { background-position: -120px -960px }
.icon-fish-15112 { background-position: -160px -960px }
.icon-fish-15113 { background-position: -200px -960px }
.icon-fish-15114 { background-position: -240px -960px }
.icon-fish-15115 { background-position: -280px -960px }
.icon-fish-15116 { background-position: -320px -960px }
.icon-fish-15117 { background-position: -360px -960px }
.icon-fish-15118 { background-position: -400px -960px }
.icon-fish-15119 { background-position: -440px -960px }
.icon-fish-15120 { background-position: -480px -960px }
.icon-fish-15121 { background-position: -520px -960px }
.icon-fish-15122 { background-position: -560px -960px }
.icon-fish-15123 { background-position: -600px -960px }
.icon-fish-15124 { background-position: -640px -960px }
.icon-fish-15125 { background-position: -680px -960px }
.icon-fish-15126 { background-position: -720px -960px }
.icon-fish-15127 { background-position: -760px -960px }
.icon-fish-15128 { background-position: -800px -960px }
.icon-fish-15129 { background-position: -840px -960px }
.icon-fish-15130 { background-position: -880px -960px }
.icon-fish-15131 { background-position: -920px -960px }
.icon-fish-15132 { background-position: -960px -960px }
.icon-fish-15133 { background-position: -1000px 0px }
.icon-fish-15134 { background-position: -1000px -40px }
.icon-fish-15135 { background-position: -1000px -80px }
.icon-fish-15136 { background-position: -1000px -120px }
.icon-fish-15137 { background-position: -1000px -160px }
.icon-fish-15138 { background-position: -1000px -200px }
.icon-fish-15139 { background-position: -1000px -240px }
.icon-fish-15140 { background-position: -1000px -280px }
.icon-fish-15141 { background-position: -1000px -320px }
.icon-fish-15142 { background-position: -1000px -360px }
.icon-fish-15143 { background-position: -1000px -400px }
.icon-fish-15144 { background-position: -1000px -440px }
.icon-fish-15145 { background-position: -1000px -480px }
.icon-fish-15146 { background-position: -1000px -520px }
.icon-fish-15147 { background-position: -1000px -560px }
.icon-fish-15148 { background-position: -1000px -600px }
.icon-fish-15149 { background-position: -1000px -640px }
.icon-fish-15150 { background-position: -1000px -680px }
.icon-fish-15151 { background-position: -1000px -720px }
.icon-fish-15152 { background-position: -1000px -760px }
.icon-fish-15153 { background-position: -1000px -800px }
.icon-fish-15154 { background-position: -1000px -840px }
.icon-fish-15155 { background-position: -1000px -880px }
.icon-fish-15156 { background-position: -1000px -920px }
.icon-fish-15157 { background-position: -1000px -960px }
.icon-fish-15158 { background-position: 0px -1000px }
.icon-fish-15159 { background-position: -40px -1000px }
.icon-fish-15160 { background-position: -80px -1000px }
.icon-fish-15161 { background-position: -120px -1000px }
.icon-fish-15162 { background-position: -160px -1000px }
.icon-fish-15163 { background-position: -200px -1000px }
.icon-fish-15164 { background-position: -240px -1000px }
.icon-fish-15165 { background-position: -280px -1000px }
.icon-fish-15166 { background-position: -320px -1000px }
.icon-fish-15167 { background-position: -360px -1000px }
.icon-fish-15168 { background-position: -400px -1000px }
.icon-fish-15169 { background-position: -440px -1000px }
.icon-fish-15170 { background-position: -480px -1000px }
.icon-fish-15171 { background-position: -520px -1000px }
.icon-fish-15172 { background-position: -560px -1000px }
.icon-fish-15173 { background-position: -600px -1000px }
.icon-fish-15174 { background-position: -640px -1000px }
.icon-fish-15175 { background-position: -680px -1000px }
.icon-fish-15176 { background-position: -720px -1000px }
.icon-fish-15177 { background-position: -760px -1000px }
.icon-fish-15178 { background-position: -800px -1000px }
.icon-fish-15179 { background-position: -840px -1000px }
.icon-fish-15180 { background-position: -880px -1000px }
.icon-fish-15181 { background-position: -920px -1000px }
.icon-fish-15182 { background-position: -960px -1000px }
.icon-fish-15183 { background-position: -1000px -1000px }
.icon-fish-15184 { background-position: -1040px 0px }
.icon-fish-15185 { background-position: -1040px -40px }
.icon-fish-15186 { background-position: -1040px -80px }
.icon-fish-15187 { background-position: -1040px -120px }
.icon-fish-15188 { background-position: -1040px -160px }
.icon-fish-152 { background-position: -1040px -200px }
.icon-fish-153 { background-position: -1040px -240px }
.icon-fish-154 { background-position: -1040px -280px }
.icon-fish-155 { background-position: -1040px -320px }
.icon-fish-156 { background-position: -1040px -360px }
.icon-fish-157 { background-position: -1040px -400px }
.icon-fish-158 { background-position: -1040px -440px }
.icon-fish-159 { background-position: -1040px -480px }
.icon-fish-16 { background-position: -1040px -520px }
.icon-fish-160 { background-position: -1040px -560px }
.icon-fish-161 { background-position: -1040px -600px }
.icon-fish-162 { background-position: -1040px -640px }
.icon-fish-163 { background-position: -1040px -680px }
.icon-fish-164 { background-position: -1040px -720px }
.icon-fish-165 { background-position: -1040px -760px }
.icon-fish-166 { background-position: -1040px -800px }
.icon-fish-167 { background-position: -1040px -840px }
.icon-fish-168 { background-position: -1040px -880px }
.icon-fish-169 { background-position: -1040px -920px }
.icon-fish-17 { background-position: -1040px -960px }
.icon-fish-170 { background-position: -1040px -1000px }
.icon-fish-171 { background-position: 0px -1040px }
.icon-fish-172 { background-position: -40px -1040px }
.icon-fish-173 { background-position: -80px -1040px }
.icon-fish-174 { background-position: -120px -1040px }
.icon-fish-175 { background-position: -160px -1040px }
.icon-fish-176 { background-position: -200px -1040px }
.icon-fish-177 { background-position: -240px -1040px }
.icon-fish-178 { background-position: -280px -1040px }
.icon-fish-179 { background-position: -320px -1040px }
.icon-fish-18 { background-position: -360px -1040px }
.icon-fish-180 { background-position: -400px -1040px }
.icon-fish-181 { background-position: -440px -1040px }
.icon-fish-182 { background-position: -480px -1040px }
.icon-fish-183 { background-position: -520px -1040px }
.icon-fish-184 { background-position: -560px -1040px }
.icon-fish-185 { background-position: -600px -1040px }
.icon-fish-186 { background-position: -640px -1040px }
.icon-fish-187 { background-position: -680px -1040px }
.icon-fish-188 { background-position: -720px -1040px }
.icon-fish-189 { background-position: -760px -1040px }
.icon-fish-19 { background-position: -800px -1040px }
.icon-fish-190 { background-position: -840px -1040px }
.icon-fish-191 { background-position: -880px -1040px }
.icon-fish-192 { background-position: -920px -1040px }
.icon-fish-193 { background-position: -960px -1040px }
.icon-fish-194 { background-position: -1000px -1040px }
.icon-fish-195 { background-position: -1040px -1040px }
.icon-fish-196 { background-position: -1080px 0px }
.icon-fish-197 { background-position: -1080px -40px }
.icon-fish-198 { background-position: -1080px -80px }
.icon-fish-199 { background-position: -1080px -120px }
.icon-fish-2 { background-position: -1080px -160px }
.icon-fish-20 { background-position: -1080px -200px }
.icon-fish-200 { background-position: -1080px -240px }
.icon-fish-20000 { background-position: -1080px -280px }
.icon-fish-20001 { background-position: -1080px -320px }
.icon-fish-20002 { background-position: -1080px -360px }
.icon-fish-20003 { background-position: -1080px -400px }
.icon-fish-20004 { background-position: -1080px -440px }
.icon-fish-20005 { background-position: -1080px -480px }
.icon-fish-20006 { background-position: -1080px -520px }
.icon-fish-20007 { background-position: -1080px -560px }
.icon-fish-20008 { background-position: -1080px -600px }
.icon-fish-20009 { background-position: -1080px -640px }
.icon-fish-20010 { background-position: -1080px -680px }
.icon-fish-20011 { background-position: -1080px -720px }
.icon-fish-20012 { background-position: -1080px -760px }
.icon-fish-20013 { background-position: -1080px -800px }
.icon-fish-20014 { background-position: -1080px -840px }
.icon-fish-20015 { background-position: -1080px -880px }
.icon-fish-20016 { background-position: -1080px -920px }
.icon-fish-20017 { background-position: -1080px -960px }
.icon-fish-20018 { background-position: -1080px -1000px }
.icon-fish-20019 { background-position: -1080px -1040px }
.icon-fish-20020 { background-position: 0px -1080px }
.icon-fish-20021 { background-position: -40px -1080px }
.icon-fish-20022 { background-position: -80px -1080px }
.icon-fish-20023 { background-position: -120px -1080px }
.icon-fish-20024 { background-position: -160px -1080px }
.icon-fish-20025 { background-position: -200px -1080px }
.icon-fish-20026 { background-position: -240px -1080px }
.icon-fish-20027 { background-position: -280px -1080px }
.icon-fish-20028 { background-position: -320px -1080px }
.icon-fish-20029 { background-position: -360px -1080px }
.icon-fish-20030 { background-position: -400px -1080px }
.icon-fish-20031 { background-position: -440px -1080px }
.icon-fish-20032 { background-position: -480px -1080px }
.icon-fish-20033 { background-position: -520px -1080px }
.icon-fish-20034 { background-position: -560px -1080px }
.icon-fish-20035 { background-position: -600px -1080px }
.icon-fish-20036 { background-position: -640px -1080px }
.icon-fish-20037 { background-position: -680px -1080px }
.icon-fish-20038 { background-position: -720px -1080px }
.icon-fish-20039 { background-position: -760px -1080px }
.icon-fish-20040 { background-position: -800px -1080px }
.icon-fish-20041 { background-position: -840px -1080px }
.icon-fish-20042 { background-position: -880px -1080px }
.icon-fish-20043 { background-position: -920px -1080px }
.icon-fish-20044 { background-position: -960px -1080px }
.icon-fish-20045 { background-position: -1000px -1080px }
.icon-fish-20046 { background-position: -1040px -1080px }
.icon-fish-20047 { background-position: -1080px -1080px }
.icon-fish-20048 { background-position: -1120px 0px }
.icon-fish-20049 { background-position: -1120px -40px }
.icon-fish-20050 { background-position: -1120px -80px }
.icon-fish-20051 { background-position: -1120px -120px }
.icon-fish-20052 { background-position: -1120px -160px }
.icon-fish-20053 { background-position: -1120px -200px }
.icon-fish-20054 { background-position: -1120px -240px }
.icon-fish-20055 { background-position: -1120px -280px }
.icon-fish-20056 { background-position: -1120px -320px }
.icon-fish-20057 { background-position: -1120px -360px }
.icon-fish-20058 { background-position: -1120px -400px }
.icon-fish-20059 { background-position: -1120px -440px }
.icon-fish-20060 { background-position: -1120px -480px }
.icon-fish-20061 { background-position: -1120px -520px }
.icon-fish-20062 { background-position: -1120px -560px }
.icon-fish-20063 { background-position: -1120px -600px }
.icon-fish-20064 { background-position: -1120px -640px }
.icon-fish-20065 { background-position: -1120px -680px }
.icon-fish-20066 { background-position: -1120px -720px }
.icon-fish-20067 { background-position: -1120px -760px }
.icon-fish-20068 { background-position: -1120px -800px }
.icon-fish-20069 { background-position: -1120px -840px }
.icon-fish-20070 { background-position: -1120px -880px }
.icon-fish-20071 { background-position: -1120px -920px }
.icon-fish-20072 { background-position: -1120px -960px }
.icon-fish-20073 { background-position: -1120px -1000px }
.icon-fish-20074 { background-position: -1120px -1040px }
.icon-fish-20075 { background-position: -1120px -1080px }
.icon-fish-20076 { background-position: 0px -1120px }
.icon-fish-20077 { background-position: -40px -1120px }
.icon-fish-20078 { background-position: -80px -1120px }
.icon-fish-20079 { background-position: -120px -1120px }
.icon-fish-20080 { background-position: -160px -1120px }
.icon-fish-20081 { background-position: -200px -1120px }
.icon-fish-20082 { background-position: -240px -1120px }
.icon-fish-20083 { background-position: -280px -1120px }
.icon-fish-20084 { background-position: -320px -1120px }
.icon-fish-20085 { background-position: -360px -1120px }
.icon-fish-20086 { background-position: -400px -1120px }
.icon-fish-20087 { background-position: -440px -1120px }
.icon-fish-20088 { background-position: -480px -1120px }
.icon-fish-20089 { background-position: -520px -1120px }
.icon-fish-20090 { background-position: -560px -1120px }
.icon-fish-20091 { background-position: -600px -1120px }
.icon-fish-20092 { background-position: -640px -1120px }
.icon-fish-20093 { background-position: -680px -1120px }
.icon-fish-20094 { background-position: -720px -1120px }
.icon-fish-20095 { background-position: -760px -1120px }
.icon-fish-20096 { background-position: -800px -1120px }
.icon-fish-20097 { background-position: -840px -1120px }
.icon-fish-20098 { background-position: -880px -1120px }
.icon-fish-20099 { background-position: -920px -1120px }
.icon-fish-201 { background-position: -960px -1120px }
.icon-fish-20100 { background-position: -1000px -1120px }
.icon-fish-20101 { background-position: -1040px -1120px }
.icon-fish-20102 { background-position: -1080px -1120px }
.icon-fish-20103 { background-position: -1120px -1120px }
.icon-fish-20104 { background-position: -1160px 0px }
.icon-fish-20105 { background-position: -1160px -40px }
.icon-fish-20106 { background-position: -1160px -80px }
.icon-fish-20107 { background-position: -1160px -120px }
.icon-fish-20108 { background-position: -1160px -160px }
.icon-fish-20109 { background-position: -1160px -200px }
.icon-fish-20110 { background-position: -1160px -240px }
.icon-fish-20111 { background-position: -1160px -280px }
.icon-fish-20112 { background-position: -1160px -320px }
.icon-fish-20113 { background-position: -1160px -360px }
.icon-fish-20114 { background-position: -1160px -400px }
.icon-fish-20115 { background-position: -1160px -440px }
.icon-fish-20116 { background-position: -1160px -480px }
.icon-fish-20117 { background-position: -1160px -520px }
.icon-fish-20118 { background-position: -1160px -560px }
.icon-fish-20119 { background-position: -1160px -600px }
.icon-fish-20120 { background-position: -1160px -640px }
.icon-fish-20121 { background-position: -1160px -680px }
.icon-fish-20122 { background-position: -1160px -720px }
.icon-fish-20123 { background-position: -1160px -760px }
.icon-fish-20124 { background-position: -1160px -800px }
.icon-fish-20125 { background-position: -1160px -840px }
.icon-fish-20126 { background-position: -1160px -880px }
.icon-fish-20127 { background-position: -1160px -920px }
.icon-fish-20128 { background-position: -1160px -960px }
.icon-fish-20129 { background-position: -1160px -1000px }
.icon-fish-20130 { background-position: -1160px -1040px }
.icon-fish-20131 { background-position: -1160px -1080px }
.icon-fish-20132 { background-position: -1160px -1120px }
.icon-fish-20133 { background-position: 0px -1160px }
.icon-fish-20134 { background-position: -40px -1160px }
.icon-fish-20135 { background-position: -80px -1160px }
.icon-fish-20136 { background-position: -120px -1160px }
.icon-fish-20137 { background-position: -160px -1160px }
.icon-fish-20138 { background-position: -200px -1160px }
.icon-fish-20139 { background-position: -240px -1160px }
.icon-fish-20140 { background-position: -280px -1160px }
.icon-fish-20141 { background-position: -320px -1160px }
.icon-fish-20142 { background-position: -360px -1160px }
.icon-fish-20143 { background-position: -400px -1160px }
.icon-fish-20144 { background-position: -440px -1160px }
.icon-fish-20145 { background-position: -480px -1160px }
.icon-fish-20146 { background-position: -520px -1160px }
.icon-fish-20147 { background-position: -560px -1160px }
.icon-fish-20148 { background-position: -600px -1160px }
.icon-fish-20149 { background-position: -640px -1160px }
.icon-fish-20150 { background-position: -680px -1160px }
.icon-fish-20151 { background-position: -720px -1160px }
.icon-fish-20152 { background-position: -760px -1160px }
.icon-fish-20153 { background-position: -800px -1160px }
.icon-fish-20154 { background-position: -840px -1160px }
.icon-fish-20155 { background-position: -880px -1160px }
.icon-fish-20156 { background-position: -920px -1160px }
.icon-fish-20157 { background-position: -960px -1160px }
.icon-fish-20158 { background-position: -1000px -1160px }
.icon-fish-20159 { background-position: -1040px -1160px }
.icon-fish-20160 { background-position: -1080px -1160px }
.icon-fish-20161 { background-position: -1120px -1160px }
.icon-fish-20162 { background-position: -1160px -1160px }
.icon-fish-20163 { background-position: -1200px 0px }
.icon-fish-20164 { background-position: -1200px -40px }
.icon-fish-20165 { background-position: -1200px -80px }
.icon-fish-20166 { background-position: -1200px -120px }
.icon-fish-20167 { background-position: -1200px -160px }
.icon-fish-20168 { background-position: -1200px -200px }
.icon-fish-20169 { background-position: -1200px -240px }
.icon-fish-20170 { background-position: -1200px -280px }
.icon-fish-20171 { background-position: -1200px -320px }
.icon-fish-20172 { background-position: -1200px -360px }
.icon-fish-20173 { background-position: -1200px -400px }
.icon-fish-20174 { background-position: -1200px -440px }
.icon-fish-20175 { background-position: -1200px -480px }
.icon-fish-20176 { background-position: -1200px -520px }
.icon-fish-20177 { background-position: -1200px -560px }
.icon-fish-20178 { background-position: -1200px -600px }
.icon-fish-20179 { background-position: -1200px -640px }
.icon-fish-20180 { background-position: -1200px -680px }
.icon-fish-20181 { background-position: -1200px -720px }
.icon-fish-20182 { background-position: -1200px -760px }
.icon-fish-20183 { background-position: -1200px -800px }
.icon-fish-20184 { background-position: -1200px -840px }
.icon-fish-20185 { background-position: -1200px -880px }
.icon-fish-20186 { background-position: -1200px -920px }
.icon-fish-20187 { background-position: -1200px -960px }
.icon-fish-20188 { background-position: -1200px -1000px }
.icon-fish-20189 { background-position: -1200px -1040px }
.icon-fish-20190 { background-position: -1200px -1080px }
.icon-fish-20191 { background-position: -1200px -1120px }
.icon-fish-20192 { background-position: -1200px -1160px }
.icon-fish-20193 { background-position: 0px -1200px }
.icon-fish-20194 { background-position: -40px -1200px }
.icon-fish-20195 { background-position: -80px -1200px }
.icon-fish-20196 { background-position: -120px -1200px }
.icon-fish-20197 { background-position: -160px -1200px }
.icon-fish-20198 { background-position: -200px -1200px }
.icon-fish-20199 { background-position: -240px -1200px }
.icon-fish-202 { background-position: -280px -1200px }
.icon-fish-20200 { background-position: -320px -1200px }
.icon-fish-20201 { background-position: -360px -1200px }
.icon-fish-20202 { background-position: -400px -1200px }
.icon-fish-20203 { background-position: -440px -1200px }
.icon-fish-20204 { background-position: -480px -1200px }
.icon-fish-20205 { background-position: -520px -1200px }
.icon-fish-20206 { background-position: -560px -1200px }
.icon-fish-20207 { background-position: -600px -1200px }
.icon-fish-20208 { background-position: -640px -1200px }
.icon-fish-20209 { background-position: -680px -1200px }
.icon-fish-20210 { background-position: -720px -1200px }
.icon-fish-20211 { background-position: -760px -1200px }
.icon-fish-20212 { background-position: -800px -1200px }
.icon-fish-20213 { background-position: -840px -1200px }
.icon-fish-20214 { background-position: -880px -1200px }
.icon-fish-20215 { background-position: -920px -1200px }
.icon-fish-20216 { background-position: -960px -1200px }
.icon-fish-20217 { background-position: -1000px -1200px }
.icon-fish-20218 { background-position: -1040px -1200px }
.icon-fish-20219 { background-position: -1080px -1200px }
.icon-fish-20220 { background-position: -1120px -1200px }
.icon-fish-20221 { background-position: -1160px -1200px }
.icon-fish-20222 { background-position: -1200px -1200px }
.icon-fish-20223 { background-position: -1240px 0px }
.icon-fish-20224 { background-position: -1240px -40px }
.icon-fish-20225 { background-position: -1240px -80px }
.icon-fish-20226 { background-position: -1240px -120px }
.icon-fish-20227 { background-position: -1240px -160px }
.icon-fish-20228 { background-position: -1240px -200px }
.icon-fish-20229 { background-position: -1240px -240px }
.icon-fish-20230 { background-position: -1240px -280px }
.icon-fish-20231 { background-position: -1240px -320px }
.icon-fish-20232 { background-position: -1240px -360px }
.icon-fish-20233 { background-position: -1240px -400px }
.icon-fish-20234 { background-position: -1240px -440px }
.icon-fish-20235 { background-position: -1240px -480px }
.icon-fish-20236 { background-position: -1240px -520px }
.icon-fish-20237 { background-position: -1240px -560px }
.icon-fish-20238 { background-position: -1240px -600px }
.icon-fish-20239 { background-position: -1240px -640px }
.icon-fish-20240 { background-position: -1240px -680px }
.icon-fish-20241 { background-position: -1240px -720px }
.icon-fish-20242 { background-position: -1240px -760px }
.icon-fish-20243 { background-position: -1240px -800px }
.icon-fish-20244 { background-position: -1240px -840px }
.icon-fish-20245 { background-position: -1240px -880px }
.icon-fish-20246 { background-position: -1240px -920px }
.icon-fish-20247 { background-position: -1240px -960px }
.icon-fish-20248 { background-position: -1240px -1000px }
.icon-fish-20249 { background-position: -1240px -1040px }
.icon-fish-20250 { background-position: -1240px -1080px }
.icon-fish-20251 { background-position: -1240px -1120px }
.icon-fish-20252 { background-position: -1240px -1160px }
.icon-fish-20253 { background-position: -1240px -1200px }
.icon-fish-20254 { background-position: 0px -1240px }
.icon-fish-20255 { background-position: -40px -1240px }
.icon-fish-20256 { background-position: -80px -1240px }
.icon-fish-20257 { background-position: -120px -1240px }
.icon-fish-20258 { background-position: -160px -1240px }
.icon-fish-20259 { background-position: -200px -1240px }
.icon-fish-20260 { background-position: -240px -1240px }
.icon-fish-20261 { background-position: -280px -1240px }
.icon-fish-20262 { background-position: -320px -1240px }
.icon-fish-20263 { background-position: -360px -1240px }
.icon-fish-20264 { background-position: -400px -1240px }
.icon-fish-20265 { background-position: -440px -1240px }
.icon-fish-20266 { background-position: -480px -1240px }
.icon-fish-20267 { background-position: -520px -1240px }
.icon-fish-20268 { background-position: -560px -1240px }
.icon-fish-20269 { background-position: -600px -1240px }
.icon-fish-20270 { background-position: -640px -1240px }
.icon-fish-20271 { background-position: -680px -1240px }
.icon-fish-20272 { background-position: -720px -1240px }
.icon-fish-20273 { background-position: -760px -1240px }
.icon-fish-20274 { background-position: -800px -1240px }
.icon-fish-20275 { background-position: -840px -1240px }
.icon-fish-20276 { background-position: -880px -1240px }
.icon-fish-20277 { background-position: -920px -1240px }
.icon-fish-20278 { background-position: -960px -1240px }
.icon-fish-20279 { background-position: -1000px -1240px }
.icon-fish-20280 { background-position: -1040px -1240px }
.icon-fish-20281 { background-position: -1080px -1240px }
.icon-fish-20282 { background-position: -1120px -1240px }
.icon-fish-20283 { background-position: -1160px -1240px }
.icon-fish-20284 { background-position: -1200px -1240px }
.icon-fish-20285 { background-position: -1240px -1240px }
.icon-fish-20286 { background-position: -1280px 0px }
.icon-fish-20287 { background-position: -1280px -40px }
.icon-fish-20288 { background-position: -1280px -80px }
.icon-fish-203 { background-position: -1280px -120px }
.icon-fish-204 { background-position: -1280px -160px }
.icon-fish-205 { background-position: -1280px -200px }
.icon-fish-206 { background-position: -1280px -240px }
.icon-fish-207 { background-position: -1280px -280px }
.icon-fish-208 { background-position: -1280px -320px }
.icon-fish-209 { background-position: -1280px -360px }
.icon-fish-21 { background-position: -1280px -400px }
.icon-fish-210 { background-position: -1280px -440px }
.icon-fish-211 { background-position: -1280px -480px }
.icon-fish-212 { background-position: -1280px -520px }
.icon-fish-213 { background-position: -1280px -560px }
.icon-fish-214 { background-position: -1280px -600px }
.icon-fish-215 { background-position: -1280px -640px }
.icon-fish-216 { background-position: -1280px -680px }
.icon-fish-217 { background-position: -1280px -720px }
.icon-fish-218 { background-position: -1280px -760px }
.icon-fish-219 { background-position: -1280px -800px }
.icon-fish-22 { background-position: -1280px -840px }
.icon-fish-220 { background-position: -1280px -880px }
.icon-fish-221 { background-position: -1280px -920px }
.icon-fish-222 { background-position: -1280px -960px }
.icon-fish-223 { background-position: -1280px -1000px }
.icon-fish-224 { background-position: -1280px -1040px }
.icon-fish-225 { background-position: -1280px -1080px }
.icon-fish-226 { background-position: -1280px -1120px }
.icon-fish-227 { background-position: -1280px -1160px }
.icon-fish-228 { background-position: -1280px -1200px }
.icon-fish-229 { background-position: -1280px -1240px }
.icon-fish-23 { background-position: 0px -1280px }
.icon-fish-230 { background-position: -40px -1280px }
.icon-fish-231 { background-position: -80px -1280px }
.icon-fish-232 { background-position: -120px -1280px }
.icon-fish-233 { background-position: -160px -1280px }
.icon-fish-234 { background-position: -200px -1280px }
.icon-fish-235 { background-position: -240px -1280px }
.icon-fish-236 { background-position: -280px -1280px }
.icon-fish-237 { background-position: -320px -1280px }
.icon-fish-238 { background-position: -360px -1280px }
.icon-fish-239 { background-position: -400px -1280px }
.icon-fish-24 { background-position: -440px -1280px }
.icon-fish-240 { background-position: -480px -1280px }
.icon-fish-241 { background-position: -520px -1280px }
.icon-fish-242 { background-position: -560px -1280px }
.icon-fish-243 { background-position: -600px -1280px }
.icon-fish-244 { background-position: -640px -1280px }
.icon-fish-245 { background-position: -680px -1280px }
.icon-fish-246 { background-position: -720px -1280px }
.icon-fish-247 { background-position: -760px -1280px }
.icon-fish-248 { background-position: -800px -1280px }
.icon-fish-249 { background-position: -840px -1280px }
.icon-fish-25 { background-position: -880px -1280px }
.icon-fish-250 { background-position: -920px -1280px }
.icon-fish-251 { background-position: -960px -1280px }
.icon-fish-252 { background-position: -1000px -1280px }
.icon-fish-253 { background-position: -1040px -1280px }
.icon-fish-254 { background-position: -1080px -1280px }
.icon-fish-255 { background-position: -1120px -1280px }
.icon-fish-256 { background-position: -1160px -1280px }
.icon-fish-257 { background-position: -1200px -1280px }
.icon-fish-258 { background-position: -1240px -1280px }
.icon-fish-259 { background-position: -1280px -1280px }
.icon-fish-26 { background-position: -1320px 0px }
.icon-fish-260 { background-position: -1320px -40px }
.icon-fish-261 { background-position: -1320px -80px }
.icon-fish-262 { background-position: -1320px -120px }
.icon-fish-263 { background-position: -1320px -160px }
.icon-fish-264 { background-position: -1320px -200px }
.icon-fish-265 { background-position: -1320px -240px }
.icon-fish-266 { background-position: -1320px -280px }
.icon-fish-267 { background-position: -1320px -320px }
.icon-fish-268 { background-position: -1320px -360px }
.icon-fish-269 { background-position: -1320px -400px }
.icon-fish-27 { background-position: -1320px -440px }
.icon-fish-270 { background-position: -1320px -480px }
.icon-fish-271 { background-position: -1320px -520px }
.icon-fish-272 { background-position: -1320px -560px }
.icon-fish-273 { background-position: -1320px -600px }
.icon-fish-274 { background-position: -1320px -640px }
.icon-fish-275 { background-position: -1320px -680px }
.icon-fish-276 { background-position: -1320px -720px }
.icon-fish-277 { background-position: -1320px -760px }
.icon-fish-278 { background-position: -1320px -800px }
.icon-fish-279 { background-position: -1320px -840px }
.icon-fish-28 { background-position: -1320px -880px }
.icon-fish-280 { background-position: -1320px -920px }
.icon-fish-281 { background-position: -1320px -960px }
.icon-fish-282 { background-position: -1320px -1000px }
.icon-fish-283 { background-position: -1320px -1040px }
.icon-fish-284 { background-position: -1320px -1080px }
.icon-fish-285 { background-position: -1320px -1120px }
.icon-fish-286 { background-position: -1320px -1160px }
.icon-fish-287 { background-position: -1320px -1200px }
.icon-fish-288 { background-position: -1320px -1240px }
.icon-fish-289 { background-position: -1320px -1280px }
.icon-fish-29 { background-position: 0px -1320px }
.icon-fish-290 { background-position: -40px -1320px }
.icon-fish-291 { background-position: -80px -1320px }
.icon-fish-292 { background-position: -120px -1320px }
.icon-fish-293 { background-position: -160px -1320px }
.icon-fish-294 { background-position: -200px -1320px }
.icon-fish-295 { background-position: -240px -1320px }
.icon-fish-296 { background-position: -280px -1320px }
.icon-fish-297 { background-position: -320px -1320px }
.icon-fish-298 { background-position: -360px -1320px }
.icon-fish-299 { background-position: -400px -1320px }
.icon-fish-3 { background-position: -440px -1320px }
.icon-fish-30 { background-position: -480px -1320px }
.icon-fish-300 { background-position: -520px -1320px }
.icon-fish-30000 { background-position: -560px -1320px }
.icon-fish-30001 { background-position: -600px -1320px }
.icon-fish-30002 { background-position: -640px -1320px }
.icon-fish-30003 { background-position: -680px -1320px }
.icon-fish-30004 { background-position: -720px -1320px }
.icon-fish-30005 { background-position: -760px -1320px }
.icon-fish-30006 { background-position: -800px -1320px }
.icon-fish-30007 { background-position: -840px -1320px }
.icon-fish-30008 { background-position: -880px -1320px }
.icon-fish-30009 { background-position: -920px -1320px }
.icon-fish-30010 { background-position: -960px -1320px }
.icon-fish-30011 { background-position: -1000px -1320px }
.icon-fish-30012 { background-position: -1040px -1320px }
.icon-fish-30013 { background-position: -1080px -1320px }
.icon-fish-30014 { background-position: -1120px -1320px }
.icon-fish-301 { background-position: -1160px -1320px }
.icon-fish-302 { background-position: -1200px -1320px }
.icon-fish-303 { background-position: -1240px -1320px }
.icon-fish-304 { background-position: -1280px -1320px }
.icon-fish-305 { background-position: -1320px -1320px }
.icon-fish-306 { background-position: -1360px 0px }
.icon-fish-307 { background-position: -1360px -40px }
.icon-fish-308 { background-position: -1360px -80px }
.icon-fish-309 { background-position: -1360px -120px }
.icon-fish-31 { background-position: -1360px -160px }
.icon-fish-310 { background-position: -1360px -200px }
.icon-fish-311 { background-position: -1360px -240px }
.icon-fish-312 { background-position: -1360px -280px }
.icon-fish-313 { background-position: -1360px -320px }
.icon-fish-314 { background-position: -1360px -360px }
.icon-fish-315 { background-position: -1360px -400px }
.icon-fish-316 { background-position: -1360px -440px }
.icon-fish-317 { background-position: -1360px -480px }
.icon-fish-318 { background-position: -1360px -520px }
.icon-fish-319 { background-position: -1360px -560px }
.icon-fish-32 { background-position: -1360px -600px }
.icon-fish-320 { background-position: -1360px -640px }
.icon-fish-321 { background-position: -1360px -680px }
.icon-fish-322 { background-position: -1360px -720px }
.icon-fish-323 { background-position: -1360px -760px }
.icon-fish-324 { background-position: -1360px -800px }
.icon-fish-325 { background-position: -1360px -840px }
.icon-fish-326 { background-position: -1360px -880px }
.icon-fish-327 { background-position: -1360px -920px }
.icon-fish-328 { background-position: -1360px -960px }
.icon-fish-329 { background-position: -1360px -1000px }
.icon-fish-33 { background-position: -1360px -1040px }
.icon-fish-330 { background-position: -1360px -1080px }
.icon-fish-331 { background-position: -1360px -1120px }
.icon-fish-332 { background-position: -1360px -1160px }
.icon-fish-333 { background-position: -1360px -1200px }
.icon-fish-334 { background-position: -1360px -1240px }
.icon-fish-335 { background-position: -1360px -1280px }
.icon-fish-336 { background-position: -1360px -1320px }
.icon-fish-337 { background-position: 0px -1360px }
.icon-fish-338 { background-position: -40px -1360px }
.icon-fish-339 { background-position: -80px -1360px }
.icon-fish-34 { background-position: -120px -1360px }
.icon-fish-340 { background-position: -160px -1360px }
.icon-fish-341 { background-position: -200px -1360px }
.icon-fish-342 { background-position: -240px -1360px }
.icon-fish-343 { background-position: -280px -1360px }
.icon-fish-344 { background-position: -320px -1360px }
.icon-fish-345 { background-position: -360px -1360px }
.icon-fish-346 { background-position: -400px -1360px }
.icon-fish-347 { background-position: -440px -1360px }
.icon-fish-348 { background-position: -480px -1360px }
.icon-fish-349 { background-position: -520px -1360px }
.icon-fish-35 { background-position: -560px -1360px }
.icon-fish-350 { background-position: -600px -1360px }
.icon-fish-351 { background-position: -640px -1360px }
.icon-fish-352 { background-position: -680px -1360px }
.icon-fish-353 { background-position: -720px -1360px }
.icon-fish-354 { background-position: -760px -1360px }
.icon-fish-355 { background-position: -800px -1360px }
.icon-fish-356 { background-position: -840px -1360px }
.icon-fish-357 { background-position: -880px -1360px }
.icon-fish-358 { background-position: -920px -1360px }
.icon-fish-359 { background-position: -960px -1360px }
.icon-fish-36 { background-position: -1000px -1360px }
.icon-fish-360 { background-position: -1040px -1360px }
.icon-fish-361 { background-position: -1080px -1360px }
.icon-fish-362 { background-position: -1120px -1360px }
.icon-fish-363 { background-position: -1160px -1360px }
.icon-fish-364 { background-position: -1200px -1360px }
.icon-fish-365 { background-position: -1240px -1360px }
.icon-fish-366 { background-position: -1280px -1360px }
.icon-fish-367 { background-position: -1320px -1360px }
.icon-fish-368 { background-position: -1360px -1360px }
.icon-fish-369 { background-position: -1400px 0px }
.icon-fish-37 { background-position: -1400px -40px }
.icon-fish-370 { background-position: -1400px -80px }
.icon-fish-371 { background-position: -1400px -120px }
.icon-fish-372 { background-position: -1400px -160px }
.icon-fish-373 { background-position: -1400px -200px }
.icon-fish-374 { background-position: -1400px -240px }
.icon-fish-375 { background-position: -1400px -280px }
.icon-fish-376 { background-position: -1400px -320px }
.icon-fish-377 { background-position: -1400px -360px }
.icon-fish-378 { background-position: -1400px -400px }
.icon-fish-379 { background-position: -1400px -440px }
.icon-fish-38 { background-position: -1400px -480px }
.icon-fish-380 { background-position: -1400px -520px }
.icon-fish-381 { background-position: -1400px -560px }
.icon-fish-382 { background-position: -1400px -600px }
.icon-fish-383 { background-position: -1400px -640px }
.icon-fish-384 { background-position: -1400px -680px }
.icon-fish-385 { background-position: -1400px -720px }
.icon-fish-386 { background-position: -1400px -760px }
.icon-fish-387 { background-position: -1400px -800px }
.icon-fish-388 { background-position: -1400px -840px }
.icon-fish-389 { background-position: -1400px -880px }
.icon-fish-39 { background-position: -1400px -920px }
.icon-fish-390 { background-position: -1400px -960px }
.icon-fish-391 { background-position: -1400px -1000px }
.icon-fish-392 { background-position: -1400px -1040px }
.icon-fish-393 { background-position: -1400px -1080px }
.icon-fish-394 { background-position: -1400px -1120px }
.icon-fish-395 { background-position: -1400px -1160px }
.icon-fish-396 { background-position: -1400px -1200px }
.icon-fish-397 { background-position: -1400px -1240px }
.icon-fish-398 { background-position: -1400px -1280px }
.icon-fish-399 { background-position: -1400px -1320px }
.icon-fish-4 { background-position: -1400px -1360px }
.icon-fish-40 { background-position: 0px -1400px }
.icon-fish-400 { background-position: -40px -1400px }
.icon-fish-401 { background-position: -80px -1400px }
.icon-fish-402 { background-position: -120px -1400px }
.icon-fish-403 { background-position: -160px -1400px }
.icon-fish-404 { background-position: -200px -1400px }
.icon-fish-405 { background-position: -240px -1400px }
.icon-fish-406 { background-position: -280px -1400px }
.icon-fish-407 { background-position: -320px -1400px }
.icon-fish-408 { background-position: -360px -1400px }
.icon-fish-409 { background-position: -400px -1400px }
.icon-fish-41 { background-position: -440px -1400px }
.icon-fish-410 { background-position: -480px -1400px }
.icon-fish-411 { background-position: -520px -1400px }
.icon-fish-412 { background-position: -560px -1400px }
.icon-fish-413 { background-position: -600px -1400px }
.icon-fish-414 { background-position: -640px -1400px }
.icon-fish-415 { background-position: -680px -1400px }
.icon-fish-416 { background-position: -720px -1400px }
.icon-fish-417 { background-position: -760px -1400px }
.icon-fish-418 { background-position: -800px -1400px }
.icon-fish-419 { background-position: -840px -1400px }
.icon-fish-42 { background-position: -880px -1400px }
.icon-fish-420 { background-position: -920px -1400px }
.icon-fish-421 { background-position: -960px -1400px }
.icon-fish-422 { background-position: -1000px -1400px }
.icon-fish-423 { background-position: -1040px -1400px }
.icon-fish-424 { background-position: -1080px -1400px }
.icon-fish-425 { background-position: -1120px -1400px }
.icon-fish-426 { background-position: -1160px -1400px }
.icon-fish-427 { background-position: -1200px -1400px }
.icon-fish-428 { background-position: -1240px -1400px }
.icon-fish-429 { background-position: -1280px -1400px }
.icon-fish-43 { background-position: -1320px -1400px }
.icon-fish-430 { background-position: -1360px -1400px }
.icon-fish-431 { background-position: -1400px -1400px }
.icon-fish-432 { background-position: -1440px 0px }
.icon-fish-433 { background-position: -1440px -40px }
.icon-fish-434 { background-position: -1440px -80px }
.icon-fish-435 { background-position: -1440px -120px }
.icon-fish-436 { background-position: -1440px -160px }
.icon-fish-437 { background-position: -1440px -200px }
.icon-fish-438 { background-position: -1440px -240px }
.icon-fish-439 { background-position: -1440px -280px }
.icon-fish-44 { background-position: -1440px -320px }
.icon-fish-440 { background-position: -1440px -360px }
.icon-fish-441 { background-position: -1440px -400px }
.icon-fish-442 { background-position: -1440px -440px }
.icon-fish-443 { background-position: -1440px -480px }
.icon-fish-444 { background-position: -1440px -520px }
.icon-fish-445 { background-position: -1440px -560px }
.icon-fish-446 { background-position: -1440px -600px }
.icon-fish-447 { background-position: -1440px -640px }
.icon-fish-448 { background-position: -1440px -680px }
.icon-fish-449 { background-position: -1440px -720px }
.icon-fish-45 { background-position: -1440px -760px }
.icon-fish-450 { background-position: -1440px -800px }
.icon-fish-451 { background-position: -1440px -840px }
.icon-fish-452 { background-position: -1440px -880px }
.icon-fish-453 { background-position: -1440px -920px }
.icon-fish-454 { background-position: -1440px -960px }
.icon-fish-455 { background-position: -1440px -1000px }
.icon-fish-456 { background-position: -1440px -1040px }
.icon-fish-457 { background-position: -1440px -1080px }
.icon-fish-458 { background-position: -1440px -1120px }
.icon-fish-459 { background-position: -1440px -1160px }
.icon-fish-46 { background-position: -1440px -1200px }
.icon-fish-460 { background-position: -1440px -1240px }
.icon-fish-461 { background-position: -1440px -1280px }
.icon-fish-462 { background-position: -1440px -1320px }
.icon-fish-463 { background-position: -1440px -1360px }
.icon-fish-464 { background-position: -1440px -1400px }
.icon-fish-465 { background-position: 0px -1440px }
.icon-fish-466 { background-position: -40px -1440px }
.icon-fish-467 { background-position: -80px -1440px }
.icon-fish-468 { background-position: -120px -1440px }
.icon-fish-469 { background-position: -160px -1440px }
.icon-fish-47 { background-position: -200px -1440px }
.icon-fish-470 { background-position: -240px -1440px }
.icon-fish-471 { background-position: -280px -1440px }
.icon-fish-472 { background-position: -320px -1440px }
.icon-fish-473 { background-position: -360px -1440px }
.icon-fish-474 { background-position: -400px -1440px }
.icon-fish-475 { background-position: -440px -1440px }
.icon-fish-476 { background-position: -480px -1440px }
.icon-fish-477 { background-position: -520px -1440px }
.icon-fish-478 { background-position: -560px -1440px }
.icon-fish-479 { background-position: -600px -1440px }
.icon-fish-48 { background-position: -640px -1440px }
.icon-fish-480 { background-position: -680px -1440px }
.icon-fish-481 { background-position: -720px -1440px }
.icon-fish-482 { background-position: -760px -1440px }
.icon-fish-483 { background-position: -800px -1440px }
.icon-fish-484 { background-position: -840px -1440px }
.icon-fish-485 { background-position: -880px -1440px }
.icon-fish-486 { background-position: -920px -1440px }
.icon-fish-487 { background-position: -960px -1440px }
.icon-fish-488 { background-position: -1000px -1440px }
.icon-fish-489 { background-position: -1040px -1440px }
.icon-fish-49 { background-position: -1080px -1440px }
.icon-fish-490 { background-position: -1120px -1440px }
.icon-fish-491 { background-position: -1160px -1440px }
.icon-fish-492 { background-position: -1200px -1440px }
.icon-fish-493 { background-position: -1240px -1440px }
.icon-fish-494 { background-position: -1280px -1440px }
.icon-fish-495 { background-position: -1320px -1440px }
.icon-fish-496 { background-position: -1360px -1440px }
.icon-fish-497 { background-position: -1400px -1440px }
.icon-fish-498 { background-position: -1440px -1440px }
.icon-fish-499 { background-position: -1480px 0px }
.icon-fish-5 { background-position: -1480px -40px }
.icon-fish-50 { background-position: -1480px -80px }
.icon-fish-500 { background-position: -1480px -120px }
.icon-fish-501 { background-position: -1480px -160px }
.icon-fish-502 { background-position: -1480px -200px }
.icon-fish-503 { background-position: -1480px -240px }
.icon-fish-504 { background-position: -1480px -280px }
.icon-fish-505 { background-position: -1480px -320px }
.icon-fish-506 { background-position: -1480px -360px }
.icon-fish-507 { background-position: -1480px -400px }
.icon-fish-508 { background-position: -1480px -440px }
.icon-fish-509 { background-position: -1480px -480px }
.icon-fish-51 { background-position: -1480px -520px }
.icon-fish-510 { background-position: -1480px -560px }
.icon-fish-511 { background-position: -1480px -600px }
.icon-fish-512 { background-position: -1480px -640px }
.icon-fish-513 { background-position: -1480px -680px }
.icon-fish-514 { background-position: -1480px -720px }
.icon-fish-515 { background-position: -1480px -760px }
.icon-fish-516 { background-position: -1480px -800px }
.icon-fish-517 { background-position: -1480px -840px }
.icon-fish-518 { background-position: -1480px -880px }
.icon-fish-519 { background-position: -1480px -920px }
.icon-fish-52 { background-position: -1480px -960px }
.icon-fish-520 { background-position: -1480px -1000px }
.icon-fish-521 { background-position: -1480px -1040px }
.icon-fish-522 { background-position: -1480px -1080px }
.icon-fish-523 { background-position: -1480px -1120px }
.icon-fish-524 { background-position: -1480px -1160px }
.icon-fish-525 { background-position: -1480px -1200px }
.icon-fish-526 { background-position: -1480px -1240px }
.icon-fish-527 { background-position: -1480px -1280px }
.icon-fish-528 { background-position: -1480px -1320px }
.icon-fish-529 { background-position: -1480px -1360px }
.icon-fish-53 { background-position: -1480px -1400px }
.icon-fish-530 { background-position: -1480px -1440px }
.icon-fish-531 { background-position: 0px -1480px }
.icon-fish-532 { background-position: -40px -1480px }
.icon-fish-533 { background-position: -80px -1480px }
.icon-fish-534 { background-position: -120px -1480px }
.icon-fish-535 { background-position: -160px -1480px }
.icon-fish-536 { background-position: -200px -1480px }
.icon-fish-537 { background-position: -240px -1480px }
.icon-fish-538 { background-position: -280px -1480px }
.icon-fish-539 { background-position: -320px -1480px }
.icon-fish-54 { background-position: -360px -1480px }
.icon-fish-540 { background-position: -400px -1480px }
.icon-fish-541 { background-position: -440px -1480px }
.icon-fish-542 { background-position: -480px -1480px }
.icon-fish-543 { background-position: -520px -1480px }
.icon-fish-544 { background-position: -560px -1480px }
.icon-fish-545 { background-position: -600px -1480px }
.icon-fish-546 { background-position: -640px -1480px }
.icon-fish-547 { background-position: -680px -1480px }
.icon-fish-548 { background-position: -720px -1480px }
.icon-fish-549 { background-position: -760px -1480px }
.icon-fish-55 { background-position: -800px -1480px }
.icon-fish-550 { background-position: -840px -1480px }
.icon-fish-551 { background-position: -880px -1480px }
.icon-fish-552 { background-position: -920px -1480px }
.icon-fish-553 { background-position: -960px -1480px }
.icon-fish-554 { background-position: -1000px -1480px }
.icon-fish-555 { background-position: -1040px -1480px }
.icon-fish-556 { background-position: -1080px -1480px }
.icon-fish-557 { background-position: -1120px -1480px }
.icon-fish-558 { background-position: -1160px -1480px }
.icon-fish-559 { background-position: -1200px -1480px }
.icon-fish-56 { background-position: -1240px -1480px }
.icon-fish-560 { background-position: -1280px -1480px }
.icon-fish-561 { background-position: -1320px -1480px }
.icon-fish-562 { background-position: -1360px -1480px }
.icon-fish-563 { background-position: -1400px -1480px }
.icon-fish-564 { background-position: -1440px -1480px }
.icon-fish-565 { background-position: -1480px -1480px }
.icon-fish-566 { background-position: -1520px 0px }
.icon-fish-567 { background-position: -1520px -40px }
.icon-fish-568 { background-position: -1520px -80px }
.icon-fish-569 { background-position: -1520px -120px }
.icon-fish-57 { background-position: -1520px -160px }
.icon-fish-570 { background-position: -1520px -200px }
.icon-fish-571 { background-position: -1520px -240px }
.icon-fish-572 { background-position: -1520px -280px }
.icon-fish-573 { background-position: -1520px -320px }
.icon-fish-574 { background-position: -1520px -360px }
.icon-fish-575 { background-position: -1520px -400px }
.icon-fish-576 { background-position: -1520px -440px }
.icon-fish-577 { background-position: -1520px -480px }
.icon-fish-578 { background-position: -1520px -520px }
.icon-fish-579 { background-position: -1520px -560px }
.icon-fish-58 { background-position: -1520px -600px }
.icon-fish-580 { background-position: -1520px -640px }
.icon-fish-581 { background-position: -1520px -680px }
.icon-fish-582 { background-position: -1520px -720px }
.icon-fish-583 { background-position: -1520px -760px }
.icon-fish-584 { background-position: -1520px -800px }
.icon-fish-585 { background-position: -1520px -840px }
.icon-fish-586 { background-position: -1520px -880px }
.icon-fish-587 { background-position: -1520px -920px }
.icon-fish-588 { background-position: -1520px -960px }
.icon-fish-589 { background-position: -1520px -1000px }
.icon-fish-59 { background-position: -1520px -1040px }
.icon-fish-590 { background-position: -1520px -1080px }
.icon-fish-591 { background-position: -1520px -1120px }
.icon-fish-592 { background-position: -1520px -1160px }
.icon-fish-593 { background-position: -1520px -1200px }
.icon-fish-594 { background-position: -1520px -1240px }
.icon-fish-595 { background-position: -1520px -1280px }
.icon-fish-596 { background-position: -1520px -1320px }
.icon-fish-597 { background-position: -1520px -1360px }
.icon-fish-598 { background-position: -1520px -1400px }
.icon-fish-599 { background-position: -1520px -1440px }
.icon-fish-6 { background-position: -1520px -1480px }
.icon-fish-60 { background-position: 0px -1520px }
.icon-fish-600 { background-position: -40px -1520px }
.icon-fish-601 { background-position: -80px -1520px }
.icon-fish-602 { background-position: -120px -1520px }
.icon-fish-603 { background-position: -160px -1520px }
.icon-fish-604 { background-position: -200px -1520px }
.icon-fish-605 { background-position: -240px -1520px }
.icon-fish-606 { background-position: -280px -1520px }
.icon-fish-607 { background-position: -320px -1520px }
.icon-fish-608 { background-position: -360px -1520px }
.icon-fish-609 { background-position: -400px -1520px }
.icon-fish-61 { background-position: -440px -1520px }
.icon-fish-610 { background-position: -480px -1520px }
.icon-fish-611 { background-position: -520px -1520px }
.icon-fish-612 { background-position: -560px -1520px }
.icon-fish-613 { background-position: -600px -1520px }
.icon-fish-614 { background-position: -640px -1520px }
.icon-fish-615 { background-position: -680px -1520px }
.icon-fish-616 { background-position: -720px -1520px }
.icon-fish-617 { background-position: -760px -1520px }
.icon-fish-618 { background-position: -800px -1520px }
.icon-fish-619 { background-position: -840px -1520px }
.icon-fish-62 { background-position: -880px -1520px }
.icon-fish-620 { background-position: -920px -1520px }
.icon-fish-621 { background-position: -960px -1520px }
.icon-fish-622 { background-position: -1000px -1520px }
.icon-fish-623 { background-position: -1040px -1520px }
.icon-fish-624 { background-position: -1080px -1520px }
.icon-fish-625 { background-position: -1120px -1520px }
.icon-fish-626 { background-position: -1160px -1520px }
.icon-fish-627 { background-position: -1200px -1520px }
.icon-fish-628 { background-position: -1240px -1520px }
.icon-fish-629 { background-position: -1280px -1520px }
.icon-fish-63 { background-position: -1320px -1520px }
.icon-fish-630 { background-position: -1360px -1520px }
.icon-fish-631 { background-position: -1400px -1520px }
.icon-fish-632 { background-position: -1440px -1520px }
.icon-fish-633 { background-position: -1480px -1520px }
.icon-fish-634 { background-position: -1520px -1520px }
.icon-fish-635 { background-position: -1560px 0px }
.icon-fish-636 { background-position: -1560px -40px }
.icon-fish-637 { background-position: -1560px -80px }
.icon-fish-638 { background-position: -1560px -120px }
.icon-fish-639 { background-position: -1560px -160px }
.icon-fish-64 { background-position: -1560px -200px }
.icon-fish-640 { background-position: -1560px -240px }
.icon-fish-641 { background-position: -1560px -280px }
.icon-fish-642 { background-position: -1560px -320px }
.icon-fish-643 { background-position: -1560px -360px }
.icon-fish-644 { background-position: -1560px -400px }
.icon-fish-645 { background-position: -1560px -440px }
.icon-fish-646 { background-position: -1560px -480px }
.icon-fish-647 { background-position: -1560px -520px }
.icon-fish-648 { background-position: -1560px -560px }
.icon-fish-649 { background-position: -1560px -600px }
.icon-fish-65 { background-position: -1560px -640px }
.icon-fish-650 { background-position: -1560px -680px }
.icon-fish-651 { background-position: -1560px -720px }
.icon-fish-652 { background-position: -1560px -760px }
.icon-fish-653 { background-position: -1560px -800px }
.icon-fish-654 { background-position: -1560px -840px }
.icon-fish-655 { background-position: -1560px -880px }
.icon-fish-656 { background-position: -1560px -920px }
.icon-fish-657 { background-position: -1560px -960px }
.icon-fish-658 { background-position: -1560px -1000px }
.icon-fish-659 { background-position: -1560px -1040px }
.icon-fish-66 { background-position: -1560px -1080px }
.icon-fish-660 { background-position: -1560px -1120px }
.icon-fish-661 { background-position: -1560px -1160px }
.icon-fish-662 { background-position: -1560px -1200px }
.icon-fish-663 { background-position: -1560px -1240px }
.icon-fish-664 { background-position: -1560px -1280px }
.icon-fish-665 { background-position: -1560px -1320px }
.icon-fish-666 { background-position: -1560px -1360px }
.icon-fish-667 { background-position: -1560px -1400px }
.icon-fish-668 { background-position: -1560px -1440px }
.icon-fish-669 { background-position: -1560px -1480px }
.icon-fish-67 { background-position: -1560px -1520px }
.icon-fish-670 { background-position: 0px -1560px }
.icon-fish-671 { background-position: -40px -1560px }
.icon-fish-672 { background-position: -80px -1560px }
.icon-fish-673 { background-position: -120px -1560px }
.icon-fish-674 { background-position: -160px -1560px }
.icon-fish-675 { background-position: -200px -1560px }
.icon-fish-676 { background-position: -240px -1560px }
.icon-fish-677 { background-position: -280px -1560px }
.icon-fish-678 { background-position: -320px -1560px }
.icon-fish-679 { background-position: -360px -1560px }
.icon-fish-68 { background-position: -400px -1560px }
.icon-fish-680 { background-position: -440px -1560px }
.icon-fish-681 { background-position: -480px -1560px }
.icon-fish-682 { background-position: -520px -1560px }
.icon-fish-683 { background-position: -560px -1560px }
.icon-fish-684 { background-position: -600px -1560px }
.icon-fish-685 { background-position: -640px -1560px }
.icon-fish-686 { background-position: -680px -1560px }
.icon-fish-687 { background-position: -720px -1560px }
.icon-fish-688 { background-position: -760px -1560px }
.icon-fish-689 { background-position: -800px -1560px }
.icon-fish-69 { background-position: -840px -1560px }
.icon-fish-690 { background-position: -880px -1560px }
.icon-fish-691 { background-position: -920px -1560px }
.icon-fish-692 { background-position: -960px -1560px }
.icon-fish-693 { background-position: -1000px -1560px }
.icon-fish-694 { background-position: -1040px -1560px }
.icon-fish-695 { background-position: -1080px -1560px }
.icon-fish-696 { background-position: -1120px -1560px }
.icon-fish-697 { background-position: -1160px -1560px }
.icon-fish-698 { background-position: -1200px -1560px }
.icon-fish-699 { background-position: -1240px -1560px }
.icon-fish-7 { background-position: -1280px -1560px }
.icon-fish-70 { background-position: -1320px -1560px }
.icon-fish-700 { background-position: -1360px -1560px }
.icon-fish-701 { background-position: -1400px -1560px }
.icon-fish-702 { background-position: -1440px -1560px }
.icon-fish-703 { background-position: -1480px -1560px }
.icon-fish-704 { background-position: -1520px -1560px }
.icon-fish-705 { background-position: -1560px -1560px }
.icon-fish-706 { background-position: -1600px 0px }
.icon-fish-707 { background-position: -1600px -40px }
.icon-fish-708 { background-position: -1600px -80px }
.icon-fish-709 { background-position: -1600px -120px }
.icon-fish-71 { background-position: -1600px -160px }
.icon-fish-710 { background-position: -1600px -200px }
.icon-fish-711 { background-position: -1600px -240px }
.icon-fish-712 { background-position: -1600px -280px }
.icon-fish-713 { background-position: -1600px -320px }
.icon-fish-714 { background-position: -1600px -360px }
.icon-fish-715 { background-position: -1600px -400px }
.icon-fish-716 { background-position: -1600px -440px }
.icon-fish-717 { background-position: -1600px -480px }
.icon-fish-718 { background-position: -1600px -520px }
.icon-fish-719 { background-position: -1600px -560px }
.icon-fish-72 { background-position: -1600px -600px }
.icon-fish-720 { background-position: -1600px -640px }
.icon-fish-721 { background-position: -1600px -680px }
.icon-fish-722 { background-position: -1600px -720px }
.icon-fish-723 { background-position: -1600px -760px }
.icon-fish-724 { background-position: -1600px -800px }
.icon-fish-725 { background-position: -1600px -840px }
.icon-fish-726 { background-position: -1600px -880px }
.icon-fish-727 { background-position: -1600px -920px }
.icon-fish-728 { background-position: -1600px -960px }
.icon-fish-729 { background-position: -1600px -1000px }
.icon-fish-73 { background-position: -1600px -1040px }
.icon-fish-730 { background-position: -1600px -1080px }
.icon-fish-731 { background-position: -1600px -1120px }
.icon-fish-732 { background-position: -1600px -1160px }
.icon-fish-733 { background-position: -1600px -1200px }
.icon-fish-734 { background-position: -1600px -1240px }
.icon-fish-735 { background-position: -1600px -1280px }
.icon-fish-736 { background-position: -1600px -1320px }
.icon-fish-737 { background-position: -1600px -1360px }
.icon-fish-738 { background-position: -1600px -1400px }
.icon-fish-739 { background-position: -1600px -1440px }
.icon-fish-74 { background-position: -1600px -1480px }
.icon-fish-740 { background-position: -1600px -1520px }
.icon-fish-741 { background-position: -1600px -1560px }
.icon-fish-742 { background-position: 0px -1600px }
.icon-fish-743 { background-position: -40px -1600px }
.icon-fish-744 { background-position: -80px -1600px }
.icon-fish-745 { background-position: -120px -1600px }
.icon-fish-746 { background-position: -160px -1600px }
.icon-fish-747 { background-position: -200px -1600px }
.icon-fish-748 { background-position: -240px -1600px }
.icon-fish-749 { background-position: -280px -1600px }
.icon-fish-75 { background-position: -320px -1600px }
.icon-fish-750 { background-position: -360px -1600px }
.icon-fish-751 { background-position: -400px -1600px }
.icon-fish-752 { background-position: -440px -1600px }
.icon-fish-753 { background-position: -480px -1600px }
.icon-fish-754 { background-position: -520px -1600px }
.icon-fish-755 { background-position: -560px -1600px }
.icon-fish-756 { background-position: -600px -1600px }
.icon-fish-757 { background-position: -640px -1600px }
.icon-fish-758 { background-position: -680px -1600px }
.icon-fish-759 { background-position: -720px -1600px }
.icon-fish-76 { background-position: -760px -1600px }
.icon-fish-760 { background-position: -800px -1600px }
.icon-fish-761 { background-position: -840px -1600px }
.icon-fish-762 { background-position: -880px -1600px }
.icon-fish-763 { background-position: -920px -1600px }
.icon-fish-764 { background-position: -960px -1600px }
.icon-fish-765 { background-position: -1000px -1600px }
.icon-fish-766 { background-position: -1040px -1600px }
.icon-fish-767 { background-position: -1080px -1600px }
.icon-fish-768 { background-position: -1120px -1600px }
.icon-fish-769 { background-position: -1160px -1600px }
.icon-fish-77 { background-position: -1200px -1600px }
.icon-fish-770 { background-position: -1240px -1600px }
.icon-fish-771 { background-position: -1280px -1600px }
.icon-fish-772 { background-position: -1320px -1600px }
.icon-fish-773 { background-position: -1360px -1600px }
.icon-fish-774 { background-position: -1400px -1600px }
.icon-fish-775 { background-position: -1440px -1600px }
.icon-fish-776 { background-position: -1480px -1600px }
.icon-fish-777 { background-position: -1520px -1600px }
.icon-fish-778 { background-position: -1560px -1600px }
.icon-fish-779 { background-position: -1600px -1600px }
.icon-fish-78 { background-position: -1640px 0px }
.icon-fish-780 { background-position: -1640px -40px }
.icon-fish-781 { background-position: -1640px -80px }
.icon-fish-782 { background-position: -1640px -120px }
.icon-fish-783 { background-position: -1640px -160px }
.icon-fish-784 { background-position: -1640px -200px }
.icon-fish-785 { background-position: -1640px -240px }
.icon-fish-786 { background-position: -1640px -280px }
.icon-fish-787 { background-position: -1640px -320px }
.icon-fish-788 { background-position: -1640px -360px }
.icon-fish-789 { background-position: -1640px -400px }
.icon-fish-79 { background-position: -1640px -440px }
.icon-fish-790 { background-position: -1640px -480px }
.icon-fish-791 { background-position: -1640px -520px }
.icon-fish-792 { background-position: -1640px -560px }
.icon-fish-793 { background-position: -1640px -600px }
.icon-fish-794 { background-position: -1640px -640px }
.icon-fish-795 { background-position: -1640px -680px }
.icon-fish-796 { background-position: -1640px -720px }
.icon-fish-797 { background-position: -1640px -760px }
.icon-fish-798 { background-position: -1640px -800px }
.icon-fish-799 { background-position: -1640px -840px }
.icon-fish-8 { background-position: -1640px -880px }
.icon-fish-80 { background-position: -1640px -920px }
.icon-fish-800 { background-position: -1640px -960px }
.icon-fish-801 { background-position: -1640px -1000px }
.icon-fish-802 { background-position: -1640px -1040px }
.icon-fish-803 { background-position: -1640px -1080px }
.icon-fish-804 { background-position: -1640px -1120px }
.icon-fish-805 { background-position: -1640px -1160px }
.icon-fish-806 { background-position: -1640px -1200px }
.icon-fish-807 { background-position: -1640px -1240px }
.icon-fish-808 { background-position: -1640px -1280px }
.icon-fish-809 { background-position: -1640px -1320px }
.icon-fish-81 { background-position: -1640px -1360px }
.icon-fish-810 { background-position: -1640px -1400px }
.icon-fish-811 { background-position: -1640px -1440px }
.icon-fish-812 { background-position: -1640px -1480px }
.icon-fish-813 { background-position: -1640px -1520px }
.icon-fish-814 { background-position: -1640px -1560px }
.icon-fish-815 { background-position: -1640px -1600px }
.icon-fish-816 { background-position: 0px -1640px }
.icon-fish-817 { background-position: -40px -1640px }
.icon-fish-818 { background-position: -80px -1640px }
.icon-fish-819 { background-position: -120px -1640px }
.icon-fish-82 { background-position: -160px -1640px }
.icon-fish-820 { background-position: -200px -1640px }
.icon-fish-821 { background-position: -240px -1640px }
.icon-fish-822 { background-position: -280px -1640px }
.icon-fish-823 { background-position: -320px -1640px }
.icon-fish-824 { background-position: -360px -1640px }
.icon-fish-825 { background-position: -400px -1640px }
.icon-fish-826 { background-position: -440px -1640px }
.icon-fish-827 { background-position: -480px -1640px }
.icon-fish-828 { background-position: -520px -1640px }
.icon-fish-829 { background-position: -560px -1640px }
.icon-fish-83 { background-position: -600px -1640px }
.icon-fish-830 { background-position: -640px -1640px }
.icon-fish-831 { background-position: -680px -1640px }
.icon-fish-832 { background-position: -720px -1640px }
.icon-fish-833 { background-position: -760px -1640px }
.icon-fish-834 { background-position: -800px -1640px }
.icon-fish-835 { background-position: -840px -1640px }
.icon-fish-836 { background-position: -880px -1640px }
.icon-fish-837 { background-position: -920px -1640px }
.icon-fish-838 { background-position: -960px -1640px }
.icon-fish-839 { background-position: -1000px -1640px }
.icon-fish-84 { background-position: -1040px -1640px }
.icon-fish-840 { background-position: -1080px -1640px }
.icon-fish-841 { background-position: -1120px -1640px }
.icon-fish-842 { background-position: -1160px -1640px }
.icon-fish-843 { background-position: -1200px -1640px }
.icon-fish-844 { background-position: -1240px -1640px }
.icon-fish-845 { background-position: -1280px -1640px }
.icon-fish-846 { background-position: -1320px -1640px }
.icon-fish-847 { background-position: -1360px -1640px }
.icon-fish-848 { background-position: -1400px -1640px }
.icon-fish-849 { background-position: -1440px -1640px }
.icon-fish-85 { background-position: -1480px -1640px }
.icon-fish-850 { background-position: -1520px -1640px }
.icon-fish-851 { background-position: -1560px -1640px }
.icon-fish-852 { background-position: -1600px -1640px }
.icon-fish-853 { background-position: -1640px -1640px }
.icon-fish-854 { background-position: -1680px 0px }
.icon-fish-855 { background-position: -1680px -40px }
.icon-fish-856 { background-position: -1680px -80px }
.icon-fish-857 { background-position: -1680px -120px }
.icon-fish-858 { background-position: -1680px -160px }
.icon-fish-859 { background-position: -1680px -200px }
.icon-fish-86 { background-position: -1680px -240px }
.icon-fish-860 { background-position: -1680px -280px }
.icon-fish-861 { background-position: -1680px -320px }
.icon-fish-862 { background-position: -1680px -360px }
.icon-fish-863 { background-position: -1680px -400px }
.icon-fish-864 { background-position: -1680px -440px }
.icon-fish-865 { background-position: -1680px -480px }
.icon-fish-866 { background-position: -1680px -520px }
.icon-fish-867 { background-position: -1680px -560px }
.icon-fish-868 { background-position: -1680px -600px }
.icon-fish-869 { background-position: -1680px -640px }
.icon-fish-87 { background-position: -1680px -680px }
.icon-fish-870 { background-position: -1680px -720px }
.icon-fish-871 { background-position: -1680px -760px }
.icon-fish-872 { background-position: -1680px -800px }
.icon-fish-873 { background-position: -1680px -840px }
.icon-fish-874 { background-position: -1680px -880px }
.icon-fish-875 { background-position: -1680px -920px }
.icon-fish-876 { background-position: -1680px -960px }
.icon-fish-877 { background-position: -1680px -1000px }
.icon-fish-878 { background-position: -1680px -1040px }
.icon-fish-879 { background-position: -1680px -1080px }
.icon-fish-88 { background-position: -1680px -1120px }
.icon-fish-880 { background-position: -1680px -1160px }
.icon-fish-881 { background-position: -1680px -1200px }
.icon-fish-882 { background-position: -1680px -1240px }
.icon-fish-883 { background-position: -1680px -1280px }
.icon-fish-884 { background-position: -1680px -1320px }
.icon-fish-885 { background-position: -1680px -1360px }
.icon-fish-886 { background-position: -1680px -1400px }
.icon-fish-887 { background-position: -1680px -1440px }
.icon-fish-888 { background-position: -1680px -1480px }
.icon-fish-889 { background-position: -1680px -1520px }
.icon-fish-89 { background-position: -1680px -1560px }
.icon-fish-890 { background-position: -1680px -1600px }
.icon-fish-891 { background-position: -1680px -1640px }
.icon-fish-892 { background-position: 0px -1680px }
.icon-fish-893 { background-position: -40px -1680px }
.icon-fish-894 { background-position: -80px -1680px }
.icon-fish-895 { background-position: -120px -1680px }
.icon-fish-896 { background-position: -160px -1680px }
.icon-fish-897 { background-position: -200px -1680px }
.icon-fish-898 { background-position: -240px -1680px }
.icon-fish-899 { background-position: -280px -1680px }
.icon-fish-9 { background-position: -320px -1680px }
.icon-fish-90 { background-position: -360px -1680px }
.icon-fish-900 { background-position: -400px -1680px }
.icon-fish-901 { background-position: -440px -1680px }
.icon-fish-902 { background-position: -480px -1680px }
.icon-fish-903 { background-position: -520px -1680px }
.icon-fish-904 { background-position: -560px -1680px }
.icon-fish-905 { background-position: -600px -1680px }
.icon-fish-906 { background-position: -640px -1680px }
.icon-fish-907 { background-position: -680px -1680px }
.icon-fish-908 { background-position: -720px -1680px }
.icon-fish-909 { background-position: -760px -1680px }
.icon-fish-91 { background-position: -800px -1680px }
.icon-fish-910 { background-position: -840px -1680px }
.icon-fish-911 { background-position: -880px -1680px }
.icon-fish-912 { background-position: -920px -1680px }
.icon-fish-913 { background-position: -960px -1680px }
.icon-fish-914 { background-position: -1000px -1680px }
.icon-fish-915 { background-position: -1040px -1680px }
.icon-fish-916 { background-position: -1080px -1680px }
.icon-fish-917 { background-position: -1120px -1680px }
.icon-fish-918 { background-position: -1160px -1680px }
.icon-fish-919 { background-position: -1200px -1680px }
.icon-fish-92 { background-position: -1240px -1680px }
.icon-fish-920 { background-position: -1280px -1680px }
.icon-fish-921 { background-position: -1320px -1680px }
.icon-fish-922 { background-position: -1360px -1680px }
.icon-fish-923 { background-position: -1400px -1680px }
.icon-fish-924 { background-position: -1440px -1680px }
.icon-fish-925 { background-position: -1480px -1680px }
.icon-fish-926 { background-position: -1520px -1680px }
.icon-fish-927 { background-position: -1560px -1680px }
.icon-fish-928 { background-position: -1600px -1680px }
.icon-fish-929 { background-position: -1640px -1680px }
.icon-fish-93 { background-position: -1680px -1680px }
.icon-fish-930 { background-position: -1720px 0px }
.icon-fish-931 { background-position: -1720px -40px }
.icon-fish-932 { background-position: -1720px -80px }
.icon-fish-933 { background-position: -1720px -120px }
.icon-fish-934 { background-position: -1720px -160px }
.icon-fish-935 { background-position: -1720px -200px }
.icon-fish-936 { background-position: -1720px -240px }
.icon-fish-937 { background-position: -1720px -280px }
.icon-fish-938 { background-position: -1720px -320px }
.icon-fish-939 { background-position: -1720px -360px }
.icon-fish-94 { background-position: -1720px -400px }
.icon-fish-940 { background-position: -1720px -440px }
.icon-fish-941 { background-position: -1720px -480px }
.icon-fish-942 { background-position: -1720px -520px }
.icon-fish-943 { background-position: -1720px -560px }
.icon-fish-944 { background-position: -1720px -600px }
.icon-fish-945 { background-position: -1720px -640px }
.icon-fish-946 { background-position: -1720px -680px }
.icon-fish-947 { background-position: -1720px -720px }
.icon-fish-948 { background-position: -1720px -760px }
.icon-fish-949 { background-position: -1720px -800px }
.icon-fish-95 { background-position: -1720px -840px }
.icon-fish-950 { background-position: -1720px -880px }
.icon-fish-951 { background-position: -1720px -920px }
.icon-fish-952 { background-position: -1720px -960px }
.icon-fish-953 { background-position: -1720px -1000px }
.icon-fish-954 { background-position: -1720px -1040px }
.icon-fish-955 { background-position: -1720px -1080px }
.icon-fish-956 { background-position: -1720px -1120px }
.icon-fish-957 { background-position: -1720px -1160px }
.icon-fish-958 { background-position: -1720px -1200px }
.icon-fish-959 { background-position: -1720px -1240px }
.icon-fish-96 { background-position: -1720px -1280px }
.icon-fish-960 { background-position: -1720px -1320px }
.icon-fish-961 { background-position: -1720px -1360px }
.icon-fish-962 { background-position: -1720px -1400px }
.icon-fish-963 { background-position: -1720px -1440px }
.icon-fish-964 { background-position: -1720px -1480px }
.icon-fish-965 { background-position: -1720px -1520px }
.icon-fish-966 { background-position: -1720px -1560px }
.icon-fish-967 { background-position: -1720px -1600px }
.icon-fish-968 { background-position: -1720px -1640px }
.icon-fish-969 { background-position: -1720px -1680px }
.icon-fish-97 { background-position: 0px -1720px }
.icon-fish-970 { background-position: -40px -1720px }
.icon-fish-971 { background-position: -80px -1720px }
.icon-fish-972 { background-position: -120px -1720px }
.icon-fish-973 { background-position: -160px -1720px }
.icon-fish-974 { background-position: -200px -1720px }
.icon-fish-975 { background-position: -240px -1720px }
.icon-fish-976 { background-position: -280px -1720px }
.icon-fish-977 { background-position: -320px -1720px }
.icon-fish-978 { background-position: -360px -1720px }
.icon-fish-979 { background-position: -400px -1720px }
.icon-fish-98 { background-position: -440px -1720px }
.icon-fish-980 { background-position: -480px -1720px }
.icon-fish-981 { background-position: -520px -1720px }
.icon-fish-982 { background-position: -560px -1720px }
.icon-fish-983 { background-position: -600px -1720px }
.icon-fish-984 { background-position: -640px -1720px }
.icon-fish-985 { background-position: -680px -1720px }
.icon-fish-986 { background-position: -720px -1720px }
.icon-fish-987 { background-position: -760px -1720px }
.icon-fish-988 { background-position: -800px -1720px }
.icon-fish-989 { background-position: -840px -1720px }
.icon-fish-99 { background-position: -880px -1720px }
.icon-fish-990 { background-position: -920px -1720px }
.icon-fish-991 { background-position: -960px -1720px }
.icon-fish-992 { background-position: -1000px -1720px }
.icon-fish-993 { background-position: -1040px -1720px }
.icon-fish-994 { background-position: -1080px -1720px }
.icon-fish-995 { background-position: -1120px -1720px }
.icon-fish-996 { background-position: -1160px -1720px }
.icon-fish-997 { background-position: -1200px -1720px }
.icon-fish-998 { background-position: -1240px -1720px }
.icon-fish-999 { background-position: -1280px -1720px }