
.icon-barding {
	background-image: url(./barding.png);
	width: 40px;
	height: 40px;
}
.icon-barding-100b { background-position: 0px 0px }
.icon-barding-100h { background-position: -40px 0px }
.icon-barding-100l { background-position: 0px -40px }
.icon-barding-101b { background-position: -40px -40px }
.icon-barding-101h { background-position: -80px 0px }
.icon-barding-101l { background-position: -80px -40px }
.icon-barding-102b { background-position: 0px -80px }
.icon-barding-102h { background-position: -40px -80px }
.icon-barding-102l { background-position: -80px -80px }
.icon-barding-10b { background-position: -120px 0px }
.icon-barding-10h { background-position: -120px -40px }
.icon-barding-10l { background-position: -120px -80px }
.icon-barding-11b { background-position: 0px -120px }
.icon-barding-11h { background-position: -40px -120px }
.icon-barding-11l { background-position: -80px -120px }
.icon-barding-12b { background-position: -120px -120px }
.icon-barding-12h { background-position: -160px 0px }
.icon-barding-12l { background-position: -160px -40px }
.icon-barding-13b { background-position: -160px -80px }
.icon-barding-13h { background-position: -160px -120px }
.icon-barding-13l { background-position: 0px -160px }
.icon-barding-14b { background-position: -40px -160px }
.icon-barding-14h { background-position: -80px -160px }
.icon-barding-14l { background-position: -120px -160px }
.icon-barding-15b { background-position: -160px -160px }
.icon-barding-15h { background-position: -200px 0px }
.icon-barding-15l { background-position: -200px -40px }
.icon-barding-16b { background-position: -200px -80px }
.icon-barding-16h { background-position: -200px -120px }
.icon-barding-16l { background-position: -200px -160px }
.icon-barding-17b { background-position: 0px -200px }
.icon-barding-17h { background-position: -40px -200px }
.icon-barding-17l { background-position: -80px -200px }
.icon-barding-18b { background-position: -120px -200px }
.icon-barding-18h { background-position: -160px -200px }
.icon-barding-18l { background-position: -200px -200px }
.icon-barding-19b { background-position: -240px 0px }
.icon-barding-19h { background-position: -240px -40px }
.icon-barding-19l { background-position: -240px -80px }
.icon-barding-1b { background-position: -240px -120px }
.icon-barding-1h { background-position: -240px -160px }
.icon-barding-1l { background-position: -240px -200px }
.icon-barding-20b { background-position: 0px -240px }
.icon-barding-20h { background-position: -40px -240px }
.icon-barding-20l { background-position: -80px -240px }
.icon-barding-21b { background-position: -120px -240px }
.icon-barding-21h { background-position: -160px -240px }
.icon-barding-21l { background-position: -200px -240px }
.icon-barding-22b { background-position: -240px -240px }
.icon-barding-22h { background-position: -280px 0px }
.icon-barding-22l { background-position: -280px -40px }
.icon-barding-23b { background-position: -280px -80px }
.icon-barding-23h { background-position: -280px -120px }
.icon-barding-23l { background-position: -280px -160px }
.icon-barding-24b { background-position: -280px -200px }
.icon-barding-24h { background-position: -280px -240px }
.icon-barding-24l { background-position: 0px -280px }
.icon-barding-25b { background-position: -40px -280px }
.icon-barding-25h { background-position: -80px -280px }
.icon-barding-25l { background-position: -120px -280px }
.icon-barding-26b { background-position: -160px -280px }
.icon-barding-26h { background-position: -200px -280px }
.icon-barding-26l { background-position: -240px -280px }
.icon-barding-27b { background-position: -280px -280px }
.icon-barding-27h { background-position: -320px 0px }
.icon-barding-27l { background-position: -320px -40px }
.icon-barding-28b { background-position: -320px -80px }
.icon-barding-28h { background-position: -320px -120px }
.icon-barding-28l { background-position: -320px -160px }
.icon-barding-29b { background-position: -320px -200px }
.icon-barding-29h { background-position: -320px -240px }
.icon-barding-29l { background-position: -320px -280px }
.icon-barding-2b { background-position: 0px -320px }
.icon-barding-2h { background-position: -40px -320px }
.icon-barding-2l { background-position: -80px -320px }
.icon-barding-30b { background-position: -120px -320px }
.icon-barding-30h { background-position: -160px -320px }
.icon-barding-30l { background-position: -200px -320px }
.icon-barding-31b { background-position: -240px -320px }
.icon-barding-31h { background-position: -280px -320px }
.icon-barding-31l { background-position: -320px -320px }
.icon-barding-32b { background-position: -360px 0px }
.icon-barding-32h { background-position: -360px -40px }
.icon-barding-32l { background-position: -360px -80px }
.icon-barding-34b { background-position: -360px -120px }
.icon-barding-34h { background-position: -360px -160px }
.icon-barding-34l { background-position: -360px -200px }
.icon-barding-35b { background-position: -360px -240px }
.icon-barding-35h { background-position: -360px -280px }
.icon-barding-35l { background-position: -360px -320px }
.icon-barding-36b { background-position: 0px -360px }
.icon-barding-36h { background-position: -40px -360px }
.icon-barding-36l { background-position: -80px -360px }
.icon-barding-37b { background-position: -120px -360px }
.icon-barding-37h { background-position: -160px -360px }
.icon-barding-37l { background-position: -200px -360px }
.icon-barding-38b { background-position: -240px -360px }
.icon-barding-38h { background-position: -280px -360px }
.icon-barding-38l { background-position: -320px -360px }
.icon-barding-39b { background-position: -360px -360px }
.icon-barding-39h { background-position: -400px 0px }
.icon-barding-39l { background-position: -400px -40px }
.icon-barding-3b { background-position: -400px -80px }
.icon-barding-3h { background-position: -400px -120px }
.icon-barding-3l { background-position: -400px -160px }
.icon-barding-40b { background-position: -400px -200px }
.icon-barding-40h { background-position: -400px -240px }
.icon-barding-40l { background-position: -400px -280px }
.icon-barding-41b { background-position: -400px -320px }
.icon-barding-41h { background-position: -400px -360px }
.icon-barding-41l { background-position: 0px -400px }
.icon-barding-42b { background-position: -40px -400px }
.icon-barding-42h { background-position: -80px -400px }
.icon-barding-42l { background-position: -120px -400px }
.icon-barding-43b { background-position: -160px -400px }
.icon-barding-43h { background-position: -200px -400px }
.icon-barding-43l { background-position: -240px -400px }
.icon-barding-44b { background-position: -280px -400px }
.icon-barding-44h { background-position: -320px -400px }
.icon-barding-44l { background-position: -360px -400px }
.icon-barding-45b { background-position: -400px -400px }
.icon-barding-45h { background-position: -440px 0px }
.icon-barding-45l { background-position: -440px -40px }
.icon-barding-46b { background-position: -440px -80px }
.icon-barding-46h { background-position: -440px -120px }
.icon-barding-46l { background-position: -440px -160px }
.icon-barding-47b { background-position: -440px -200px }
.icon-barding-47h { background-position: -440px -240px }
.icon-barding-47l { background-position: -440px -280px }
.icon-barding-48b { background-position: -440px -320px }
.icon-barding-48h { background-position: -440px -360px }
.icon-barding-48l { background-position: -440px -400px }
.icon-barding-49b { background-position: 0px -440px }
.icon-barding-49h { background-position: -40px -440px }
.icon-barding-49l { background-position: -80px -440px }
.icon-barding-4b { background-position: -120px -440px }
.icon-barding-4h { background-position: -160px -440px }
.icon-barding-4l { background-position: -200px -440px }
.icon-barding-50b { background-position: -240px -440px }
.icon-barding-50h { background-position: -280px -440px }
.icon-barding-50l { background-position: -320px -440px }
.icon-barding-51b { background-position: -360px -440px }
.icon-barding-51h { background-position: -400px -440px }
.icon-barding-51l { background-position: -440px -440px }
.icon-barding-52b { background-position: -480px 0px }
.icon-barding-52h { background-position: -480px -40px }
.icon-barding-52l { background-position: -480px -80px }
.icon-barding-53b { background-position: -480px -120px }
.icon-barding-53h { background-position: -480px -160px }
.icon-barding-53l { background-position: -480px -200px }
.icon-barding-54b { background-position: -480px -240px }
.icon-barding-54h { background-position: -480px -280px }
.icon-barding-54l { background-position: -480px -320px }
.icon-barding-55b { background-position: -480px -360px }
.icon-barding-55h { background-position: -480px -400px }
.icon-barding-55l { background-position: -480px -440px }
.icon-barding-56b { background-position: 0px -480px }
.icon-barding-56h { background-position: -40px -480px }
.icon-barding-56l { background-position: -80px -480px }
.icon-barding-57b { background-position: -120px -480px }
.icon-barding-57h { background-position: -160px -480px }
.icon-barding-57l { background-position: -200px -480px }
.icon-barding-58b { background-position: -240px -480px }
.icon-barding-58h { background-position: -280px -480px }
.icon-barding-58l { background-position: -320px -480px }
.icon-barding-59b { background-position: -360px -480px }
.icon-barding-59h { background-position: -400px -480px }
.icon-barding-59l { background-position: -440px -480px }
.icon-barding-5b { background-position: -480px -480px }
.icon-barding-5h { background-position: -520px 0px }
.icon-barding-5l { background-position: -520px -40px }
.icon-barding-60b { background-position: -520px -80px }
.icon-barding-60h { background-position: -520px -120px }
.icon-barding-60l { background-position: -520px -160px }
.icon-barding-61b { background-position: -520px -200px }
.icon-barding-61h { background-position: -520px -240px }
.icon-barding-61l { background-position: -520px -280px }
.icon-barding-62b { background-position: -520px -320px }
.icon-barding-62h { background-position: -520px -360px }
.icon-barding-62l { background-position: -520px -400px }
.icon-barding-63b { background-position: -520px -440px }
.icon-barding-63h { background-position: -520px -480px }
.icon-barding-63l { background-position: 0px -520px }
.icon-barding-64b { background-position: -40px -520px }
.icon-barding-64h { background-position: -80px -520px }
.icon-barding-64l { background-position: -120px -520px }
.icon-barding-65b { background-position: -160px -520px }
.icon-barding-65h { background-position: -200px -520px }
.icon-barding-65l { background-position: -240px -520px }
.icon-barding-66b { background-position: -280px -520px }
.icon-barding-66h { background-position: -320px -520px }
.icon-barding-66l { background-position: -360px -520px }
.icon-barding-67b { background-position: -400px -520px }
.icon-barding-67h { background-position: -440px -520px }
.icon-barding-67l { background-position: -480px -520px }
.icon-barding-68b { background-position: -520px -520px }
.icon-barding-68h { background-position: -560px 0px }
.icon-barding-68l { background-position: -560px -40px }
.icon-barding-69b { background-position: -560px -80px }
.icon-barding-69h { background-position: -560px -120px }
.icon-barding-69l { background-position: -560px -160px }
.icon-barding-6b { background-position: -560px -200px }
.icon-barding-6h { background-position: -560px -240px }
.icon-barding-6l { background-position: -560px -280px }
.icon-barding-70b { background-position: -560px -320px }
.icon-barding-70h { background-position: -560px -360px }
.icon-barding-70l { background-position: -560px -400px }
.icon-barding-71b { background-position: -560px -440px }
.icon-barding-71h { background-position: -560px -480px }
.icon-barding-71l { background-position: -560px -520px }
.icon-barding-72b { background-position: 0px -560px }
.icon-barding-72h { background-position: -40px -560px }
.icon-barding-72l { background-position: -80px -560px }
.icon-barding-73b { background-position: -120px -560px }
.icon-barding-73h { background-position: -160px -560px }
.icon-barding-73l { background-position: -200px -560px }
.icon-barding-74b { background-position: -240px -560px }
.icon-barding-74h { background-position: -280px -560px }
.icon-barding-74l { background-position: -320px -560px }
.icon-barding-75b { background-position: -360px -560px }
.icon-barding-75h { background-position: -400px -560px }
.icon-barding-75l { background-position: -440px -560px }
.icon-barding-76b { background-position: -480px -560px }
.icon-barding-76h { background-position: -520px -560px }
.icon-barding-76l { background-position: -560px -560px }
.icon-barding-77b { background-position: -600px 0px }
.icon-barding-77h { background-position: -600px -40px }
.icon-barding-77l { background-position: -600px -80px }
.icon-barding-78b { background-position: -600px -120px }
.icon-barding-78h { background-position: -600px -160px }
.icon-barding-78l { background-position: -600px -200px }
.icon-barding-79b { background-position: -600px -240px }
.icon-barding-79h { background-position: -600px -280px }
.icon-barding-79l { background-position: -600px -320px }
.icon-barding-7b { background-position: -600px -360px }
.icon-barding-7h { background-position: -600px -400px }
.icon-barding-7l { background-position: -600px -440px }
.icon-barding-80b { background-position: -600px -480px }
.icon-barding-80h { background-position: -600px -520px }
.icon-barding-80l { background-position: -600px -560px }
.icon-barding-81b { background-position: 0px -600px }
.icon-barding-81h { background-position: -40px -600px }
.icon-barding-81l { background-position: -80px -600px }
.icon-barding-82b { background-position: -120px -600px }
.icon-barding-82h { background-position: -160px -600px }
.icon-barding-82l { background-position: -200px -600px }
.icon-barding-83b { background-position: -240px -600px }
.icon-barding-83h { background-position: -280px -600px }
.icon-barding-83l { background-position: -320px -600px }
.icon-barding-84b { background-position: -360px -600px }
.icon-barding-84h { background-position: -400px -600px }
.icon-barding-84l { background-position: -440px -600px }
.icon-barding-85b { background-position: -480px -600px }
.icon-barding-85h { background-position: -520px -600px }
.icon-barding-85l { background-position: -560px -600px }
.icon-barding-86b { background-position: -600px -600px }
.icon-barding-86h { background-position: -640px 0px }
.icon-barding-86l { background-position: -640px -40px }
.icon-barding-87b { background-position: -640px -80px }
.icon-barding-87h { background-position: -640px -120px }
.icon-barding-87l { background-position: -640px -160px }
.icon-barding-88b { background-position: -640px -200px }
.icon-barding-88h { background-position: -640px -240px }
.icon-barding-88l { background-position: -640px -280px }
.icon-barding-89b { background-position: -640px -320px }
.icon-barding-89h { background-position: -640px -360px }
.icon-barding-89l { background-position: -640px -400px }
.icon-barding-8b { background-position: -640px -440px }
.icon-barding-8h { background-position: -640px -480px }
.icon-barding-8l { background-position: -640px -520px }
.icon-barding-90b { background-position: -640px -560px }
.icon-barding-90h { background-position: -640px -600px }
.icon-barding-90l { background-position: 0px -640px }
.icon-barding-91b { background-position: -40px -640px }
.icon-barding-91h { background-position: -80px -640px }
.icon-barding-91l { background-position: -120px -640px }
.icon-barding-92b { background-position: -160px -640px }
.icon-barding-92h { background-position: -200px -640px }
.icon-barding-92l { background-position: -240px -640px }
.icon-barding-93b { background-position: -280px -640px }
.icon-barding-93h { background-position: -320px -640px }
.icon-barding-93l { background-position: -360px -640px }
.icon-barding-94b { background-position: -400px -640px }
.icon-barding-94h { background-position: -440px -640px }
.icon-barding-94l { background-position: -480px -640px }
.icon-barding-95b { background-position: -520px -640px }
.icon-barding-95h { background-position: -560px -640px }
.icon-barding-95l { background-position: -600px -640px }
.icon-barding-96b { background-position: -640px -640px }
.icon-barding-96h { background-position: -680px 0px }
.icon-barding-96l { background-position: -680px -40px }
.icon-barding-97b { background-position: -680px -80px }
.icon-barding-97h { background-position: -680px -120px }
.icon-barding-97l { background-position: -680px -160px }
.icon-barding-98b { background-position: -680px -200px }
.icon-barding-98h { background-position: -680px -240px }
.icon-barding-98l { background-position: -680px -280px }
.icon-barding-99b { background-position: -680px -320px }
.icon-barding-99h { background-position: -680px -360px }
.icon-barding-99l { background-position: -680px -400px }
.icon-barding-9b { background-position: -680px -440px }
.icon-barding-9h { background-position: -680px -480px }
.icon-barding-9l { background-position: -680px -520px }