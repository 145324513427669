
.icon-ttcards {
	background-image: url(./ttcards.png);
	width: 40px;
	height: 40px;
}
.icon-ttcards-1 { background-position: 0px 0px }
.icon-ttcards-10 { background-position: -40px 0px }
.icon-ttcards-100 { background-position: 0px -40px }
.icon-ttcards-101 { background-position: -40px -40px }
.icon-ttcards-102 { background-position: -80px 0px }
.icon-ttcards-103 { background-position: -80px -40px }
.icon-ttcards-104 { background-position: 0px -80px }
.icon-ttcards-105 { background-position: -40px -80px }
.icon-ttcards-106 { background-position: -80px -80px }
.icon-ttcards-107 { background-position: -120px 0px }
.icon-ttcards-108 { background-position: -120px -40px }
.icon-ttcards-109 { background-position: -120px -80px }
.icon-ttcards-11 { background-position: 0px -120px }
.icon-ttcards-110 { background-position: -40px -120px }
.icon-ttcards-111 { background-position: -80px -120px }
.icon-ttcards-112 { background-position: -120px -120px }
.icon-ttcards-113 { background-position: -160px 0px }
.icon-ttcards-114 { background-position: -160px -40px }
.icon-ttcards-115 { background-position: -160px -80px }
.icon-ttcards-116 { background-position: -160px -120px }
.icon-ttcards-117 { background-position: 0px -160px }
.icon-ttcards-118 { background-position: -40px -160px }
.icon-ttcards-119 { background-position: -80px -160px }
.icon-ttcards-12 { background-position: -120px -160px }
.icon-ttcards-120 { background-position: -160px -160px }
.icon-ttcards-121 { background-position: -200px 0px }
.icon-ttcards-122 { background-position: -200px -40px }
.icon-ttcards-123 { background-position: -200px -80px }
.icon-ttcards-124 { background-position: -200px -120px }
.icon-ttcards-125 { background-position: -200px -160px }
.icon-ttcards-126 { background-position: 0px -200px }
.icon-ttcards-127 { background-position: -40px -200px }
.icon-ttcards-128 { background-position: -80px -200px }
.icon-ttcards-129 { background-position: -120px -200px }
.icon-ttcards-13 { background-position: -160px -200px }
.icon-ttcards-130 { background-position: -200px -200px }
.icon-ttcards-131 { background-position: -240px 0px }
.icon-ttcards-132 { background-position: -240px -40px }
.icon-ttcards-133 { background-position: -240px -80px }
.icon-ttcards-134 { background-position: -240px -120px }
.icon-ttcards-135 { background-position: -240px -160px }
.icon-ttcards-136 { background-position: -240px -200px }
.icon-ttcards-137 { background-position: 0px -240px }
.icon-ttcards-138 { background-position: -40px -240px }
.icon-ttcards-139 { background-position: -80px -240px }
.icon-ttcards-14 { background-position: -120px -240px }
.icon-ttcards-140 { background-position: -160px -240px }
.icon-ttcards-141 { background-position: -200px -240px }
.icon-ttcards-142 { background-position: -240px -240px }
.icon-ttcards-143 { background-position: -280px 0px }
.icon-ttcards-144 { background-position: -280px -40px }
.icon-ttcards-145 { background-position: -280px -80px }
.icon-ttcards-146 { background-position: -280px -120px }
.icon-ttcards-147 { background-position: -280px -160px }
.icon-ttcards-148 { background-position: -280px -200px }
.icon-ttcards-149 { background-position: -280px -240px }
.icon-ttcards-15 { background-position: 0px -280px }
.icon-ttcards-150 { background-position: -40px -280px }
.icon-ttcards-151 { background-position: -80px -280px }
.icon-ttcards-152 { background-position: -120px -280px }
.icon-ttcards-153 { background-position: -160px -280px }
.icon-ttcards-154 { background-position: -200px -280px }
.icon-ttcards-155 { background-position: -240px -280px }
.icon-ttcards-156 { background-position: -280px -280px }
.icon-ttcards-157 { background-position: -320px 0px }
.icon-ttcards-158 { background-position: -320px -40px }
.icon-ttcards-159 { background-position: -320px -80px }
.icon-ttcards-16 { background-position: -320px -120px }
.icon-ttcards-160 { background-position: -320px -160px }
.icon-ttcards-161 { background-position: -320px -200px }
.icon-ttcards-162 { background-position: -320px -240px }
.icon-ttcards-163 { background-position: -320px -280px }
.icon-ttcards-164 { background-position: 0px -320px }
.icon-ttcards-165 { background-position: -40px -320px }
.icon-ttcards-166 { background-position: -80px -320px }
.icon-ttcards-167 { background-position: -120px -320px }
.icon-ttcards-168 { background-position: -160px -320px }
.icon-ttcards-169 { background-position: -200px -320px }
.icon-ttcards-17 { background-position: -240px -320px }
.icon-ttcards-170 { background-position: -280px -320px }
.icon-ttcards-171 { background-position: -320px -320px }
.icon-ttcards-172 { background-position: -360px 0px }
.icon-ttcards-173 { background-position: -360px -40px }
.icon-ttcards-174 { background-position: -360px -80px }
.icon-ttcards-175 { background-position: -360px -120px }
.icon-ttcards-176 { background-position: -360px -160px }
.icon-ttcards-177 { background-position: -360px -200px }
.icon-ttcards-178 { background-position: -360px -240px }
.icon-ttcards-179 { background-position: -360px -280px }
.icon-ttcards-18 { background-position: -360px -320px }
.icon-ttcards-180 { background-position: 0px -360px }
.icon-ttcards-181 { background-position: -40px -360px }
.icon-ttcards-182 { background-position: -80px -360px }
.icon-ttcards-183 { background-position: -120px -360px }
.icon-ttcards-184 { background-position: -160px -360px }
.icon-ttcards-185 { background-position: -200px -360px }
.icon-ttcards-186 { background-position: -240px -360px }
.icon-ttcards-187 { background-position: -280px -360px }
.icon-ttcards-188 { background-position: -320px -360px }
.icon-ttcards-189 { background-position: -360px -360px }
.icon-ttcards-19 { background-position: -400px 0px }
.icon-ttcards-190 { background-position: -400px -40px }
.icon-ttcards-191 { background-position: -400px -80px }
.icon-ttcards-192 { background-position: -400px -120px }
.icon-ttcards-193 { background-position: -400px -160px }
.icon-ttcards-194 { background-position: -400px -200px }
.icon-ttcards-195 { background-position: -400px -240px }
.icon-ttcards-196 { background-position: -400px -280px }
.icon-ttcards-197 { background-position: -400px -320px }
.icon-ttcards-198 { background-position: -400px -360px }
.icon-ttcards-199 { background-position: 0px -400px }
.icon-ttcards-2 { background-position: -40px -400px }
.icon-ttcards-20 { background-position: -80px -400px }
.icon-ttcards-200 { background-position: -120px -400px }
.icon-ttcards-201 { background-position: -160px -400px }
.icon-ttcards-202 { background-position: -200px -400px }
.icon-ttcards-203 { background-position: -240px -400px }
.icon-ttcards-204 { background-position: -280px -400px }
.icon-ttcards-205 { background-position: -320px -400px }
.icon-ttcards-206 { background-position: -360px -400px }
.icon-ttcards-207 { background-position: -400px -400px }
.icon-ttcards-208 { background-position: -440px 0px }
.icon-ttcards-209 { background-position: -440px -40px }
.icon-ttcards-21 { background-position: -440px -80px }
.icon-ttcards-210 { background-position: -440px -120px }
.icon-ttcards-211 { background-position: -440px -160px }
.icon-ttcards-212 { background-position: -440px -200px }
.icon-ttcards-213 { background-position: -440px -240px }
.icon-ttcards-214 { background-position: -440px -280px }
.icon-ttcards-215 { background-position: -440px -320px }
.icon-ttcards-216 { background-position: -440px -360px }
.icon-ttcards-217 { background-position: -440px -400px }
.icon-ttcards-218 { background-position: 0px -440px }
.icon-ttcards-219 { background-position: -40px -440px }
.icon-ttcards-22 { background-position: -80px -440px }
.icon-ttcards-220 { background-position: -120px -440px }
.icon-ttcards-221 { background-position: -160px -440px }
.icon-ttcards-222 { background-position: -200px -440px }
.icon-ttcards-223 { background-position: -240px -440px }
.icon-ttcards-224 { background-position: -280px -440px }
.icon-ttcards-225 { background-position: -320px -440px }
.icon-ttcards-226 { background-position: -360px -440px }
.icon-ttcards-227 { background-position: -400px -440px }
.icon-ttcards-228 { background-position: -440px -440px }
.icon-ttcards-229 { background-position: -480px 0px }
.icon-ttcards-23 { background-position: -480px -40px }
.icon-ttcards-230 { background-position: -480px -80px }
.icon-ttcards-231 { background-position: -480px -120px }
.icon-ttcards-232 { background-position: -480px -160px }
.icon-ttcards-233 { background-position: -480px -200px }
.icon-ttcards-234 { background-position: -480px -240px }
.icon-ttcards-235 { background-position: -480px -280px }
.icon-ttcards-236 { background-position: -480px -320px }
.icon-ttcards-237 { background-position: -480px -360px }
.icon-ttcards-238 { background-position: -480px -400px }
.icon-ttcards-239 { background-position: -480px -440px }
.icon-ttcards-24 { background-position: 0px -480px }
.icon-ttcards-240 { background-position: -40px -480px }
.icon-ttcards-241 { background-position: -80px -480px }
.icon-ttcards-242 { background-position: -120px -480px }
.icon-ttcards-243 { background-position: -160px -480px }
.icon-ttcards-244 { background-position: -200px -480px }
.icon-ttcards-245 { background-position: -240px -480px }
.icon-ttcards-246 { background-position: -280px -480px }
.icon-ttcards-247 { background-position: -320px -480px }
.icon-ttcards-248 { background-position: -360px -480px }
.icon-ttcards-249 { background-position: -400px -480px }
.icon-ttcards-25 { background-position: -440px -480px }
.icon-ttcards-250 { background-position: -480px -480px }
.icon-ttcards-251 { background-position: -520px 0px }
.icon-ttcards-252 { background-position: -520px -40px }
.icon-ttcards-253 { background-position: -520px -80px }
.icon-ttcards-254 { background-position: -520px -120px }
.icon-ttcards-255 { background-position: -520px -160px }
.icon-ttcards-256 { background-position: -520px -200px }
.icon-ttcards-257 { background-position: -520px -240px }
.icon-ttcards-258 { background-position: -520px -280px }
.icon-ttcards-259 { background-position: -520px -320px }
.icon-ttcards-26 { background-position: -520px -360px }
.icon-ttcards-260 { background-position: -520px -400px }
.icon-ttcards-261 { background-position: -520px -440px }
.icon-ttcards-262 { background-position: -520px -480px }
.icon-ttcards-263 { background-position: 0px -520px }
.icon-ttcards-264 { background-position: -40px -520px }
.icon-ttcards-265 { background-position: -80px -520px }
.icon-ttcards-266 { background-position: -120px -520px }
.icon-ttcards-267 { background-position: -160px -520px }
.icon-ttcards-268 { background-position: -200px -520px }
.icon-ttcards-269 { background-position: -240px -520px }
.icon-ttcards-27 { background-position: -280px -520px }
.icon-ttcards-270 { background-position: -320px -520px }
.icon-ttcards-271 { background-position: -360px -520px }
.icon-ttcards-272 { background-position: -400px -520px }
.icon-ttcards-273 { background-position: -440px -520px }
.icon-ttcards-274 { background-position: -480px -520px }
.icon-ttcards-275 { background-position: -520px -520px }
.icon-ttcards-276 { background-position: -560px 0px }
.icon-ttcards-277 { background-position: -560px -40px }
.icon-ttcards-278 { background-position: -560px -80px }
.icon-ttcards-279 { background-position: -560px -120px }
.icon-ttcards-28 { background-position: -560px -160px }
.icon-ttcards-280 { background-position: -560px -200px }
.icon-ttcards-281 { background-position: -560px -240px }
.icon-ttcards-282 { background-position: -560px -280px }
.icon-ttcards-283 { background-position: -560px -320px }
.icon-ttcards-284 { background-position: -560px -360px }
.icon-ttcards-285 { background-position: -560px -400px }
.icon-ttcards-286 { background-position: -560px -440px }
.icon-ttcards-287 { background-position: -560px -480px }
.icon-ttcards-288 { background-position: -560px -520px }
.icon-ttcards-289 { background-position: 0px -560px }
.icon-ttcards-29 { background-position: -40px -560px }
.icon-ttcards-290 { background-position: -80px -560px }
.icon-ttcards-291 { background-position: -120px -560px }
.icon-ttcards-292 { background-position: -160px -560px }
.icon-ttcards-293 { background-position: -200px -560px }
.icon-ttcards-294 { background-position: -240px -560px }
.icon-ttcards-295 { background-position: -280px -560px }
.icon-ttcards-296 { background-position: -320px -560px }
.icon-ttcards-297 { background-position: -360px -560px }
.icon-ttcards-298 { background-position: -400px -560px }
.icon-ttcards-299 { background-position: -440px -560px }
.icon-ttcards-3 { background-position: -480px -560px }
.icon-ttcards-30 { background-position: -520px -560px }
.icon-ttcards-300 { background-position: -560px -560px }
.icon-ttcards-301 { background-position: -600px 0px }
.icon-ttcards-302 { background-position: -600px -40px }
.icon-ttcards-303 { background-position: -600px -80px }
.icon-ttcards-304 { background-position: -600px -120px }
.icon-ttcards-305 { background-position: -600px -160px }
.icon-ttcards-306 { background-position: -600px -200px }
.icon-ttcards-307 { background-position: -600px -240px }
.icon-ttcards-308 { background-position: -600px -280px }
.icon-ttcards-309 { background-position: -600px -320px }
.icon-ttcards-31 { background-position: -600px -360px }
.icon-ttcards-310 { background-position: -600px -400px }
.icon-ttcards-311 { background-position: -600px -440px }
.icon-ttcards-312 { background-position: -600px -480px }
.icon-ttcards-313 { background-position: -600px -520px }
.icon-ttcards-314 { background-position: -600px -560px }
.icon-ttcards-315 { background-position: 0px -600px }
.icon-ttcards-316 { background-position: -40px -600px }
.icon-ttcards-317 { background-position: -80px -600px }
.icon-ttcards-318 { background-position: -120px -600px }
.icon-ttcards-319 { background-position: -160px -600px }
.icon-ttcards-32 { background-position: -200px -600px }
.icon-ttcards-320 { background-position: -240px -600px }
.icon-ttcards-321 { background-position: -280px -600px }
.icon-ttcards-322 { background-position: -320px -600px }
.icon-ttcards-323 { background-position: -360px -600px }
.icon-ttcards-324 { background-position: -400px -600px }
.icon-ttcards-325 { background-position: -440px -600px }
.icon-ttcards-326 { background-position: -480px -600px }
.icon-ttcards-327 { background-position: -520px -600px }
.icon-ttcards-328 { background-position: -560px -600px }
.icon-ttcards-329 { background-position: -600px -600px }
.icon-ttcards-33 { background-position: -640px 0px }
.icon-ttcards-330 { background-position: -640px -40px }
.icon-ttcards-331 { background-position: -640px -80px }
.icon-ttcards-332 { background-position: -640px -120px }
.icon-ttcards-333 { background-position: -640px -160px }
.icon-ttcards-334 { background-position: -640px -200px }
.icon-ttcards-335 { background-position: -640px -240px }
.icon-ttcards-336 { background-position: -640px -280px }
.icon-ttcards-337 { background-position: -640px -320px }
.icon-ttcards-338 { background-position: -640px -360px }
.icon-ttcards-339 { background-position: -640px -400px }
.icon-ttcards-34 { background-position: -640px -440px }
.icon-ttcards-340 { background-position: -640px -480px }
.icon-ttcards-341 { background-position: -640px -520px }
.icon-ttcards-342 { background-position: -640px -560px }
.icon-ttcards-343 { background-position: -640px -600px }
.icon-ttcards-344 { background-position: 0px -640px }
.icon-ttcards-345 { background-position: -40px -640px }
.icon-ttcards-346 { background-position: -80px -640px }
.icon-ttcards-347 { background-position: -120px -640px }
.icon-ttcards-348 { background-position: -160px -640px }
.icon-ttcards-349 { background-position: -200px -640px }
.icon-ttcards-35 { background-position: -240px -640px }
.icon-ttcards-350 { background-position: -280px -640px }
.icon-ttcards-351 { background-position: -320px -640px }
.icon-ttcards-352 { background-position: -360px -640px }
.icon-ttcards-353 { background-position: -400px -640px }
.icon-ttcards-354 { background-position: -440px -640px }
.icon-ttcards-355 { background-position: -480px -640px }
.icon-ttcards-356 { background-position: -520px -640px }
.icon-ttcards-357 { background-position: -560px -640px }
.icon-ttcards-358 { background-position: -600px -640px }
.icon-ttcards-359 { background-position: -640px -640px }
.icon-ttcards-36 { background-position: -680px 0px }
.icon-ttcards-360 { background-position: -680px -40px }
.icon-ttcards-361 { background-position: -680px -80px }
.icon-ttcards-362 { background-position: -680px -120px }
.icon-ttcards-363 { background-position: -680px -160px }
.icon-ttcards-364 { background-position: -680px -200px }
.icon-ttcards-365 { background-position: -680px -240px }
.icon-ttcards-366 { background-position: -680px -280px }
.icon-ttcards-367 { background-position: -680px -320px }
.icon-ttcards-368 { background-position: -680px -360px }
.icon-ttcards-369 { background-position: -680px -400px }
.icon-ttcards-37 { background-position: -680px -440px }
.icon-ttcards-370 { background-position: -680px -480px }
.icon-ttcards-371 { background-position: -680px -520px }
.icon-ttcards-372 { background-position: -680px -560px }
.icon-ttcards-373 { background-position: -680px -600px }
.icon-ttcards-374 { background-position: -680px -640px }
.icon-ttcards-375 { background-position: 0px -680px }
.icon-ttcards-376 { background-position: -40px -680px }
.icon-ttcards-377 { background-position: -80px -680px }
.icon-ttcards-378 { background-position: -120px -680px }
.icon-ttcards-379 { background-position: -160px -680px }
.icon-ttcards-38 { background-position: -200px -680px }
.icon-ttcards-380 { background-position: -240px -680px }
.icon-ttcards-381 { background-position: -280px -680px }
.icon-ttcards-382 { background-position: -320px -680px }
.icon-ttcards-383 { background-position: -360px -680px }
.icon-ttcards-384 { background-position: -400px -680px }
.icon-ttcards-385 { background-position: -440px -680px }
.icon-ttcards-386 { background-position: -480px -680px }
.icon-ttcards-387 { background-position: -520px -680px }
.icon-ttcards-388 { background-position: -560px -680px }
.icon-ttcards-389 { background-position: -600px -680px }
.icon-ttcards-39 { background-position: -640px -680px }
.icon-ttcards-390 { background-position: -680px -680px }
.icon-ttcards-391 { background-position: -720px 0px }
.icon-ttcards-392 { background-position: -720px -40px }
.icon-ttcards-393 { background-position: -720px -80px }
.icon-ttcards-394 { background-position: -720px -120px }
.icon-ttcards-395 { background-position: -720px -160px }
.icon-ttcards-396 { background-position: -720px -200px }
.icon-ttcards-397 { background-position: -720px -240px }
.icon-ttcards-398 { background-position: -720px -280px }
.icon-ttcards-399 { background-position: -720px -320px }
.icon-ttcards-4 { background-position: -720px -360px }
.icon-ttcards-40 { background-position: -720px -400px }
.icon-ttcards-400 { background-position: -720px -440px }
.icon-ttcards-401 { background-position: -720px -480px }
.icon-ttcards-402 { background-position: -720px -520px }
.icon-ttcards-403 { background-position: -720px -560px }
.icon-ttcards-404 { background-position: -720px -600px }
.icon-ttcards-405 { background-position: -720px -640px }
.icon-ttcards-406 { background-position: -720px -680px }
.icon-ttcards-407 { background-position: 0px -720px }
.icon-ttcards-408 { background-position: -40px -720px }
.icon-ttcards-409 { background-position: -80px -720px }
.icon-ttcards-41 { background-position: -120px -720px }
.icon-ttcards-410 { background-position: -160px -720px }
.icon-ttcards-411 { background-position: -200px -720px }
.icon-ttcards-412 { background-position: -240px -720px }
.icon-ttcards-413 { background-position: -280px -720px }
.icon-ttcards-414 { background-position: -320px -720px }
.icon-ttcards-415 { background-position: -360px -720px }
.icon-ttcards-416 { background-position: -400px -720px }
.icon-ttcards-417 { background-position: -440px -720px }
.icon-ttcards-418 { background-position: -480px -720px }
.icon-ttcards-419 { background-position: -520px -720px }
.icon-ttcards-42 { background-position: -560px -720px }
.icon-ttcards-420 { background-position: -600px -720px }
.icon-ttcards-421 { background-position: -640px -720px }
.icon-ttcards-422 { background-position: -680px -720px }
.icon-ttcards-423 { background-position: -720px -720px }
.icon-ttcards-424 { background-position: -760px 0px }
.icon-ttcards-425 { background-position: -760px -40px }
.icon-ttcards-426 { background-position: -760px -80px }
.icon-ttcards-427 { background-position: -760px -120px }
.icon-ttcards-428 { background-position: -760px -160px }
.icon-ttcards-429 { background-position: -760px -200px }
.icon-ttcards-43 { background-position: -760px -240px }
.icon-ttcards-430 { background-position: -760px -280px }
.icon-ttcards-431 { background-position: -760px -320px }
.icon-ttcards-432 { background-position: -760px -360px }
.icon-ttcards-433 { background-position: -760px -400px }
.icon-ttcards-434 { background-position: -760px -440px }
.icon-ttcards-435 { background-position: -760px -480px }
.icon-ttcards-436 { background-position: -760px -520px }
.icon-ttcards-437 { background-position: -760px -560px }
.icon-ttcards-438 { background-position: -760px -600px }
.icon-ttcards-439 { background-position: -760px -640px }
.icon-ttcards-44 { background-position: -760px -680px }
.icon-ttcards-444 { background-position: -760px -720px }
.icon-ttcards-45 { background-position: 0px -760px }
.icon-ttcards-46 { background-position: -40px -760px }
.icon-ttcards-47 { background-position: -80px -760px }
.icon-ttcards-48 { background-position: -120px -760px }
.icon-ttcards-49 { background-position: -160px -760px }
.icon-ttcards-5 { background-position: -200px -760px }
.icon-ttcards-50 { background-position: -240px -760px }
.icon-ttcards-51 { background-position: -280px -760px }
.icon-ttcards-52 { background-position: -320px -760px }
.icon-ttcards-53 { background-position: -360px -760px }
.icon-ttcards-54 { background-position: -400px -760px }
.icon-ttcards-55 { background-position: -440px -760px }
.icon-ttcards-56 { background-position: -480px -760px }
.icon-ttcards-57 { background-position: -520px -760px }
.icon-ttcards-58 { background-position: -560px -760px }
.icon-ttcards-59 { background-position: -600px -760px }
.icon-ttcards-6 { background-position: -640px -760px }
.icon-ttcards-60 { background-position: -680px -760px }
.icon-ttcards-61 { background-position: -720px -760px }
.icon-ttcards-62 { background-position: -760px -760px }
.icon-ttcards-63 { background-position: -800px 0px }
.icon-ttcards-64 { background-position: -800px -40px }
.icon-ttcards-65 { background-position: -800px -80px }
.icon-ttcards-66 { background-position: -800px -120px }
.icon-ttcards-67 { background-position: -800px -160px }
.icon-ttcards-68 { background-position: -800px -200px }
.icon-ttcards-69 { background-position: -800px -240px }
.icon-ttcards-7 { background-position: -800px -280px }
.icon-ttcards-70 { background-position: -800px -320px }
.icon-ttcards-71 { background-position: -800px -360px }
.icon-ttcards-72 { background-position: -800px -400px }
.icon-ttcards-73 { background-position: -800px -440px }
.icon-ttcards-74 { background-position: -800px -480px }
.icon-ttcards-75 { background-position: -800px -520px }
.icon-ttcards-76 { background-position: -800px -560px }
.icon-ttcards-77 { background-position: -800px -600px }
.icon-ttcards-78 { background-position: -800px -640px }
.icon-ttcards-79 { background-position: -800px -680px }
.icon-ttcards-8 { background-position: -800px -720px }
.icon-ttcards-80 { background-position: -800px -760px }
.icon-ttcards-81 { background-position: 0px -800px }
.icon-ttcards-82 { background-position: -40px -800px }
.icon-ttcards-83 { background-position: -80px -800px }
.icon-ttcards-84 { background-position: -120px -800px }
.icon-ttcards-85 { background-position: -160px -800px }
.icon-ttcards-86 { background-position: -200px -800px }
.icon-ttcards-87 { background-position: -240px -800px }
.icon-ttcards-88 { background-position: -280px -800px }
.icon-ttcards-89 { background-position: -320px -800px }
.icon-ttcards-9 { background-position: -360px -800px }
.icon-ttcards-90 { background-position: -400px -800px }
.icon-ttcards-91 { background-position: -440px -800px }
.icon-ttcards-92 { background-position: -480px -800px }
.icon-ttcards-93 { background-position: -520px -800px }
.icon-ttcards-94 { background-position: -560px -800px }
.icon-ttcards-95 { background-position: -600px -800px }
.icon-ttcards-96 { background-position: -640px -800px }
.icon-ttcards-97 { background-position: -680px -800px }
.icon-ttcards-98 { background-position: -720px -800px }
.icon-ttcards-99 { background-position: -760px -800px }