
.icon-moogletomes {
	background-image: url(./moogletomes.png);
	width: 40px;
	height: 40px;
}
.icon-moogletomes-13366 { background-position: 0px 0px }
.icon-moogletomes-14191 { background-position: -40px 0px }
.icon-moogletomes-16784 { background-position: 0px -40px }
.icon-moogletomes-17470 { background-position: -40px -40px }
.icon-moogletomes-17495 { background-position: -80px 0px }
.icon-moogletomes-20548 { background-position: -80px -40px }
.icon-moogletomes-20589 { background-position: 0px -80px }
.icon-moogletomes-20590 { background-position: -40px -80px }
.icon-moogletomes-20591 { background-position: -80px -80px }
.icon-moogletomes-20820 { background-position: -120px 0px }
.icon-moogletomes-20822 { background-position: -120px -40px }
.icon-moogletomes-20826 { background-position: -120px -80px }
.icon-moogletomes-20828 { background-position: 0px -120px }
.icon-moogletomes-20832 { background-position: -40px -120px }
.icon-moogletomes-20834 { background-position: -80px -120px }
.icon-moogletomes-20838 { background-position: -120px -120px }
.icon-moogletomes-20840 { background-position: -160px 0px }
.icon-moogletomes-20844 { background-position: -160px -40px }
.icon-moogletomes-20846 { background-position: -160px -80px }
.icon-moogletomes-20850 { background-position: -160px -120px }
.icon-moogletomes-20852 { background-position: 0px -160px }
.icon-moogletomes-20856 { background-position: -40px -160px }
.icon-moogletomes-20858 { background-position: -80px -160px }
.icon-moogletomes-21047 { background-position: -120px -160px }
.icon-moogletomes-24229 { background-position: -160px -160px }
.icon-moogletomes-24230 { background-position: -200px 0px }
.icon-moogletomes-24231 { background-position: -200px -40px }
.icon-moogletomes-24233 { background-position: -200px -80px }
.icon-moogletomes-24623 { background-position: -200px -120px }
.icon-moogletomes-24794 { background-position: -200px -160px }
.icon-moogletomes-25005 { background-position: 0px -200px }
.icon-moogletomes-26782 { background-position: -40px -200px }
.icon-moogletomes-26794 { background-position: -80px -200px }
.icon-moogletomes-28121 { background-position: -120px -200px }
.icon-moogletomes-28612 { background-position: -160px -200px }
.icon-moogletomes-28618 { background-position: -200px -200px }
.icon-moogletomes-28659 { background-position: -240px 0px }
.icon-moogletomes-28889 { background-position: -240px -40px }
.icon-moogletomes-30096 { background-position: -240px -80px }
.icon-moogletomes-30249 { background-position: -240px -120px }
.icon-moogletomes-31350 { background-position: -240px -160px }
.icon-moogletomes-32824 { background-position: -240px -200px }
.icon-moogletomes-33024 { background-position: 0px -240px }
.icon-moogletomes-33037 { background-position: -40px -240px }
.icon-moogletomes-33328 { background-position: -80px -240px }
.icon-moogletomes-35564 { background-position: -120px -240px }
.icon-moogletomes-35565 { background-position: -160px -240px }
.icon-moogletomes-35566 { background-position: -200px -240px }
.icon-moogletomes-35567 { background-position: -240px -240px }
.icon-moogletomes-35569 { background-position: -280px 0px }
.icon-moogletomes-39593 { background-position: -280px -40px }
.icon-moogletomes-39918 { background-position: -280px -80px }
.icon-moogletomes-6211 { background-position: -280px -120px }
.icon-moogletomes-7554 { background-position: -280px -160px }
.icon-moogletomes-7555 { background-position: -280px -200px }
.icon-moogletomes-7894 { background-position: -280px -240px }